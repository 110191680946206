import {getFormData} from "../functions/getFormData";
import {validateInputs} from "../helpers/InputValidator";
import { translate } from "../../views/main";

export function setReservationFormListener() {
  const form = $("#reservation-form");

  form.submit(function(e) {
    e.preventDefault();
    content.removeAllInputErrors();

    const formData = getFormData("#reservation-form");
    const isValidData = validateInputs("reservation");

    formData.date = `${formData.date.substring(6, 10)}-${formData.date.substring(3, 5)}-${formData.date.substring(0, 2)}`;

    if (isValidData) {
      api.orderTable(formData).then(() => {
        content.hideModalWindows(true);
        $(".popup").fadeOut();
        $("#success .modal-content")[0].innerHTML = `
              <h2>${translate('thank_you')}!</h2>
              <span>${translate('will_call_you')}</span>
              <a href="/index.html" title="" class="button">${translate('go_back_to_main')}</a>
          `;
        $("#success").fadeIn();
      })
    }
  })
}
