export function setCategoriesElementActive(idOfElement) {
    const refTags = $("a.category-anchor");
    refTags && $.each(refTags, (index, elem) => {
        let refTag = $(elem);
        let dataAnchorLink = refTag.attr('data-anchor-link');
        (dataAnchorLink && idOfElement && dataAnchorLink === idOfElement) 
            ? refTag.addClass('active') 
            : refTag.removeClass('active');
    })

}