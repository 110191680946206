export function initGlobalStore() {
  const location = window.location.href;

  if (location.includes("profile")) {
    DataHelper.store.buttons = {
      saveProfileButton: document.querySelector("#saveProfileButton"),
      logOutButton: DataHelper.getElement("#profileLogoutButton"),
    };

    DataHelper.store.inputs = {
      profileName: document.querySelector("#profileName"),
      profilePhone: document.querySelector("#profilePhone"),
      profileMail: document.querySelector("#profileMail"),
      profileBirthDate: document.querySelector("#birth"),
      profileGender: document.querySelector("#profileGender"),
      profilePromocode: document.querySelector("#profilePromocode"),

      addressStreeet: document.querySelector("#profile_addressStreet"),
      addressHouse: document.querySelector("#profile_addressHouse"),
      addressApartment: document.querySelector("#profile_addressApartment"),
      addressEntrance: document.querySelector("#profile_addressEntrance"),
      addressFloor: document.querySelector('#profile_addressLevel'),
      addressBlock: document.querySelector('#profile_addressBlock'),
      addressComment: document.querySelector("#profile_addressComment"),


    };
  } else {
    DataHelper.store.buttons = {
      loginButton: DataHelper.getElement("#loginApplyButton"),
      regButton: DataHelper.getElement("#regApplyButton"),
      restorePassButton: DataHelper.getElement("#restorePassApplyButton"),
      restorePassSmsButton: DataHelper.getElement("#regSmsCounterButton"),
      saveProfileButton: DataHelper.getElement("#regApplyButton"),
      regButtonStepTwoApprove: DataHelper.getElement(
        "#regButtonStepTwoApprove"
      ),
      resPassFinalButton: DataHelper.getElement("#resPassFinalButton"),
      logOutButton: DataHelper.getElement("#logOut"),
    };

    DataHelper.store.inputs = {
      guestPhone: document.querySelector("#checkout_guestphone"),

      loginPhone: DataHelper.getElement("#loginForm_phoneInput"),
      loginPass: DataHelper.getElement("#loginForm_passwordInput"),

      regName: DataHelper.getElement("#regName"),
      regPhone: DataHelper.getElement("#regTel"),
      regPass: DataHelper.getElement("#regPass"),
      regPromo: DataHelper.getElement("#regPromo"),
      regSmsCode: DataHelper.getElement("#regSmsCode"),

      restorePassPhone: DataHelper.getElement("#restorePassPhone"),
      restorePass: DataHelper.getElement("#restorePass"),

      reviewFormNameInput: DataHelper.getElement("#review-form-name"),
      reviewFormPhoneInput: DataHelper.getElement("#review-form-phone"),
      reviewFormMessageInput: DataHelper.getElement("#review-form-message"),
    };
  }

  DataHelper.store.inputs = {
    ...DataHelper.store.inputs,
    reservationRestaurant: DataHelper.getElement("#reservation-restaurant"),
    reservationDate: DataHelper.getElement("#reservation-form #date"),
    reservationTime: DataHelper.getElement("#reservation-form #time"),
    reservationPhone: DataHelper.getElement("#tel-reservation")
  }

  DataHelper.store.user = null;
}
