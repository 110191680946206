import axios from 'axios';
import { apiConfig } from '../config'

export const api = axios.create(
  {
    baseURL: apiConfig.url,
  },
);

api.interceptors.response.use(
  (response) => {
      if (response.data.status !== 200) {
          throw response.data.message;
      } else {
          return response;
      }
  },
);
