import { log } from "har-validator";
import {Api} from "../../src/api/Api";

export function addClickEvent(map) {

    console.log('addClickEvent', map)
    console.log('Работает addClickEvent')
    if (map) {
        console.log('addClickEvent РАботает условие')
        let currentCity = JSON.parse(localStorage.getItem('currentCity')) || {}

        console.log('currentCity.uuid', currentCity.uuid)

        api.getPolygonsCity(currentCity.uuid)
            .then(data => {

                console.log('data', data)

                addPolygonsYandex(data.polygons, map)
            })

    }
}


export function removeAllMarkers(map) {
    if (map.markers && map.markers.length > 0) {
        for (let layer of map.markers) {
            layer.setMap(null)
        }
    }
    map.markers = []
}


export function removeAllLayers(map) {
    if (map.layers && map.layers.length > 0) {
        for (let layer of map.layers) {
            layer.setMap(null)
        }
    }
    map.layers = []
}


function addPolygons(polygons, myMap) {
    if (myMap) {

        console.log('myMap.layers', myMap)
        removeAllLayers(myMap)

        for (let polygonItem of polygons) {
            console.log('polygonItem', polygonItem)

            let polygons = new google.maps.Polygon({
                /*paths: [
                    { lat: 25.774, lng: -80.19 },
                    { lat: 18.466, lng: -66.118 },
                    { lat: 32.321, lng: -64.757 },
                    { lat: 25.774, lng: -80.19 },
                ],*/
                paths: polygonItem.polygon.map(item => {
                    let arr = item.slice().reverse()
                    return {lat: arr[0], lng: arr[1]}
                }),
                strokeColor: polygonItem.fillcolor || '#00FF0088',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: polygonItem.fillcolor || '#00FF0088',
                fillOpacity: 0.35,
            })

            polygons.setMap(myMap);

            myMap.layers = [
                ...myMap.layers || [],
                polygons
            ]
            console.log(polygons)
            polygons.addListener("click", (mapsMouseEvent) => {
                let coords = Object.values(mapsMouseEvent.latLng.toJSON())
                console.log('coords', coords, mapsMouseEvent)
                ymaps.geocode(coords).then(function (res) {

                    let firstGeoObject = res.geoObjects.get(0);
                    $('#profile_addressName').val(firstGeoObject.getAddressLine());
                    $('#address_coords').val(coords.join(','))
                    $('#address_price').val(polygonItem.price)
                    $('#address_place').val(firstGeoObject.getAddressLine())
                    $('#profile_addressStreet').val(firstGeoObject.getAddressLine())
                    $('#default_freefrom_price').val(polygonItem.freefrom);
                    $('#address_polygon_uuid').val(polygonItem.uuid)
                    $('#viewMap').hide()
                    // document.querySelector('html').style.zoom = 0.7026
                })  
            });

        }


        myMap.addListener('click',  (mapsMouseEvent) => {
            let coords = Object.values(mapsMouseEvent.latLng.toJSON())
            console.log('coords ymaps', coords, mapsMouseEvent)
            ymaps.geocode(coords).then(function (res) {
                let firstGeoObject = res.geoObjects.get(0);
                $('#address_coords').val(coords.join(','))
                $('#address_price').val(polygonItem.price)
                $('#address_place').val(firstGeoObject.getAddressLine())
                $('#profile_addressStreet').val(firstGeoObject.getAddressLine())
                $('#address_polygon_uuid').val(polygonItem.uuid)
                $('#viewMap').hide()
                // document.querySelector('html').style.zoom = 0.7026
            })
        })

    }
}

function getPolygonItemPrice(coords, polygons, myMap) {
    let polygonItemPrice;
    if (myMap) {

        console.log('myMap.layers', myMap.layers)
        removeAllLayers(myMap)

        for (let polygonItem of polygons) {
            console.log('polygonItem.polygon', polygonItem.polygon)

            let polygons = new google.maps.Polygon({
                /*paths: [
                    { lat: 25.774, lng: -80.19 },
                    { lat: 18.466, lng: -66.118 },
                    { lat: 32.321, lng: -64.757 },
                    { lat: 25.774, lng: -80.19 },
                ],*/
                paths: polygonItem.polygon.map(item => {
                    let arr = item.slice().reverse()
                    return {lat: arr[0], lng: arr[1]}
                }),
                strokeColor: polygonItem.fillcolor || '#00FF0088',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: polygonItem.fillcolor || '#00FF0088',
                fillOpacity: 0.35,
            })

            polygons.setMap(myMap);

            myMap.layers = [
                ...myMap.layers || [],
                polygons
            ]

            polygons.addListener("click", (mapsMouseEvent) => {
                let coords = Object.values(mapsMouseEvent.latLng.toJSON())
                console.log('coords', coords, mapsMouseEvent)

                ymaps.geocode(coords).then(function (res) {
                    let firstGeoObject = res.geoObjects.get(0);
                    $('#profile_addressName').val(firstGeoObject.getAddressLine());
                    $('#address_coords').val(coords.join(','))
                    $('#address_price').val(polygonItem.price)
                    $('#address_place').val(firstGeoObject.getAddressLine())
                    $('#profile_addressStreet').val(firstGeoObject.getAddressLine())
                    $('#address_polygon_uuid').val(polygonItem.uuid)
                    $('#viewMap').hide()
                    // document.querySelector('html')f = 0.7026
                })

            });

        }

    }
}



function addPolygonsYandex(polygons, myMap) {
    console.log('myMap1111',myMap);
    myMap.geoObjects.removeAll();
    for (let polygonItem of polygons) {

        console.log('polygonItem', polygonItem);

        var myPolygon = new ymaps.Polygon([
            polygonItem.polygon.map(item => item.slice().reverse()),

            //polygonItem.polygon
        ], {
            hintContent: "Выберите дом доставки"
        }, {
            fillColor: polygonItem.fillcolor || '#00FF0088',
            // The stroke width.
            strokeWidth: 2
        });


        myPolygon.events.add('click', function (e) {
            let coords = e.get('coords')
            console.log('coords', coords);
            ymaps.geocode(coords).then(function (res) {
                console.log('polygonItem', polygonItem);
                var firstGeoObject = res.geoObjects.get(0);
                $('#address_coords').val(coords.join(','))
                $('#address_price').val(polygonItem.price)
                $('#address_place').val(firstGeoObject.getAddressLine())
                $('#profile_addressStreet').val(firstGeoObject.getAddressLine())
                $('#address_polygon_uuid').val(polygonItem.uuid)
                $('#viewMap').hide()
                $('#profile_addressName').val(firstGeoObject.getAddressLine());
                // document.querySelector('html').style.zoom = 0.7026
                console.log('Надеюсь сработает', myMap)
                document.querySelector('html').style.removeProperty("zoom");
                myMap.destroy();
            })

        })

        // Adding the polygon to the map.
        myMap.geoObjects.add(myPolygon);

    }
    function isPointInsidePolygon(point, polygon) {
        var x = point[0],
          y = point[1];
      
        var inside = false;
        for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
          var xi = polygon[i][0],
            yi = polygon[i][1];
          var xj = polygon[j][0],
            yj = polygon[j][1];
      
          var intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) inside = !inside;
        }
      
        return inside;
      }

    var mySearchControl = new ymaps.control.SearchControl({
        options: {
          noPlacemark: false,
        },
      }),
      // Результаты поиска будем помещать в коллекцию.
      mySearchResults = new ymaps.GeoObjectCollection(null, {
        hintContentLayout:
          ymaps.templateLayoutFactory.createClass("$[properties.name]"),
      });
    myMap.controls.add(mySearchControl);
    myMap.geoObjects.add(mySearchResults);
    // При клике по найденному объекту метка становится красной.
    mySearchResults.events.add("click", function (e) {
      e.get("target").options.set("preset", "islands#redIcon");
    });
    // Выбранный результат помещаем в коллекцию.
    mySearchControl.events.add("resultselect", function(e) {
        var results = mySearchControl.getResultsArray();
        var index = e.get("index");
        var point = results[index].geometry.getCoordinates();
      
        var foundPolygon = null;
      
        polygons.forEach(function(polygonItem) {
          var coordinates = polygonItem.polygon.map(function(item) {
            return item.slice().reverse();
          });
      
          if (isPointInsidePolygon(point, coordinates)) {
            foundPolygon = polygonItem;
            return false; // Выходим из цикла, как только нашли полигон
          }
        });
      
        if (foundPolygon) {
          ymaps.geocode(point).then(function(res) {
            var firstGeoObject = res.geoObjects.get(0);
            $('#address_coords').val(point.join(','));
            $('#address_price').val(foundPolygon.price);
            $('#address_place').val(firstGeoObject.getAddressLine());
            $('#profile_addressStreet').val(firstGeoObject.getAddressLine());
            $('#address_polygon_uuid').val(foundPolygon.uuid);
            $('#viewMap').hide();
            $('#profile_addressName').val(firstGeoObject.getAddressLine());
      
            document.querySelector('html').style.removeProperty("zoom");
            myMap.destroy();
          });
        } else {
            const text = {
                'RU': "Этот адрес находится в не зоны доставки",
                'KZ': 'Бул мекен жай жеткизу аймагына кирмейди',
                'EN': 'This address is outside the delivery area'
            }
            // Если адрес не попадает в зону полигонов
            var balloonContent = text[localStorage.getItem('currentLang') || 'RU'];
            myMap.balloon.open(point, balloonContent);
          }
      
        mySearchControl.getResult(index).then(function(res) {
          mySearchResults.add(res);
        });
      })
      .add("submit", function () {
        mySearchResults.removeAll();
      });
}


function createPlacemark(coords) {
    return new ymaps.Placemark(coords, {
        iconCaption: 'поиск...'

    });
}

// Определяем адрес по координатам (обратное геокодирование).
function getAddress(coords, myPlacemark) {
    myPlacemark.properties.set('iconCaption', 'поиск...');
    return ymaps.geocode(coords).then(function (res) {
        var firstGeoObject = res.geoObjects.get(0);

        myPlacemark.properties
            .set({
                // Формируем строку с данными об объекте.
                iconCaption: [
                    // Название населенного пункта или вышестоящее административно-территориальное образование.
                    firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                    // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
                    firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                ].filter(Boolean).join(', '),
                // В качестве контента балуна задаем строку с адресом объекта.
                balloonContent: firstGeoObject.getAddressLine()
            });

        console.log('firstGeoObject.getAddressLine()', firstGeoObject.getAddressLine())

        setTimeout(() => {
            if (confirm(`Вы выбрали свой адрес?`)) {
                $('#address_coords').val(coords.join(','))
                $('#address_place').val(firstGeoObject.getAddressLine())
                $('#profile_addressStreet').val(firstGeoObject.getAddressLine())
                $('#address_polygon_uuid').val(polygonItem.uuid)
                $('#viewMap').hide()
                // document.querySelector('html').style.zoom = 0.7026
            }
        }, 1000)

    });
}
