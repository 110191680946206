import {activateRegSmsCounter} from "../helpers/SmsCounter";
import {validateInputs} from "../helpers/InputValidator";
import {dropDownInit} from "./dropDown";
import { translate } from "../../views/main";

export function setAuthRegButtonListeners(onLogin) {

    const { buttons } = DataHelper.store;
    const { inputs } = DataHelper.store;

    buttons.loginButton.onclick = function(e) {
        e.preventDefault();

        const isInputsValid = validateInputs('login');

         if (isInputsValid) {
             content.removeAllInputErrors();
             auth.auth({
                 login: inputs.loginPhone.value,
                 password: inputs.loginPass.value,
             }).then(()=>{
                 api.getUserProfile().then(()=>{
                     api.getUsersBonusBalance().then((bonuses) => {
                         content.setProfileHeader(DataHelper.store.user, bonuses);
                         dropDownInit();
                         onLogin && onLogin();
                         content.hideModalWindows(false);
                     })
                 });

             }).catch((err)=>{
                err = translate(err);
                content.setToastMessage(true ,err);
             });
         }
    };

    buttons.regButton.onclick = function(e) {
        e.preventDefault();

        const isInputsValid = validateInputs('reg1');

        if (isInputsValid) {
            content.removeAllInputErrors();
            auth.register({
                login: inputs.regPhone.value,
                password: inputs.regPass.value,
                name: inputs.regName.value
            }, 1).then((data)=>{
                content.hideModalWindows(false);
                content.showSmsModal(true);
                activateRegSmsCounter();
            }).catch((err)=>{
                err = translate(err);
                content.setToastMessage(true ,err);
            });
        }
    };

    buttons.resPassFinalButton.onclick = function(e) {
        e.preventDefault();

        const phone = inputs.restorePassPhone.value;
        const pass = inputs.restorePass.value;
        const code  = inputs.regSmsCode.value;
        const signinModal = $("#signin");

        api.restoreUserPassword(phone, pass, code, 2).then(() => {
            content.hideModalWindows(true);
            content.setToastMessage(false ,'Пароль успешно изменен!');
            signinModal.fadeIn();
        }).catch((err)=>{
            err = translate(err);
            content.hideModalWindows(false);
            content.setToastMessage(true ,err);
        });
    }

    buttons.regButtonStepTwoApprove.onclick = function(e) {
        e.preventDefault();

        const code  = inputs.regSmsCode.value;

        if (code.length > 3) {
            auth.register({
                password: inputs.regPass.value,
                name: inputs.regName.value,
                smscode: code,
            }, 2).then(({})=>{
                content.hideModalWindows(true);
                content.removeAllInputErrors();
            }).catch((err)=>{
                err = translate(err);
                content.setToastMessage(true ,err);
            });
        } else {
            content.setInputError(inputs.regSmsCode, 'Пожалуйста, введите корректный код!');
        }
    };

        buttons.restorePassButton.onclick =  function(e) {
            e.preventDefault();
            const phone = inputs.restorePassPhone.value;

            if (phone) {
                activateRegSmsCounter();
                api.restoreUserPassword(phone, null, null, 1).then(() => {
                    content.showSmsModal(false);
                }).catch((err)=>{
                    err = translate(err);
                    content.setToastMessage(true ,err);
                });
            } else {
                let msg = translate('Пожалуйста, заполните все поля!');
                content.setToastMessage(true, msg);
            }

        };

        buttons.restorePassSmsButton.onclick =  function(e) {
            e.preventDefault();

            const phone = inputs.restorePassPhone.value;

            api.restoreUserPassword(phone, null, null, 1).then(() => {
            }).catch((err)=>{
                err = translate(err);
                content.setToastMessage(true ,err);
            });
            activateRegSmsCounter();
    };
}
