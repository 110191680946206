import { setCategoriesElementActive } from "../functions/setCategoriesElementActive";
import { checkIfBrowserSafari } from '../functions/checkIfBrowserSafari';

export function categoriesMenuSelectListener () {
    let additionalScale = checkIfBrowserSafari() ? 0.7026 : 1;

    $(window).on("scroll", function () {
        let windowHeight = $(window).outerHeight(true);
        let scrollTop = $('html').scrollTop();
        // console.log(scrollTop);
        let mid = scrollTop + Math.floor(windowHeight / 2);
        const sections = $("section.category-anchor");
        sections && $.each(sections, (index, elem) => {
            let section = $(elem);
            const bottomSection = (section.offset().top + section.outerHeight(true)) * additionalScale;
            const topSection = section.offset().top * additionalScale;
            if(bottomSection > mid && topSection < mid){
                // console.log(scrollTop, windowHeight, bottomSection, mid, topSection, section.attr('id'));
                let id = section.attr('id');
                setCategoriesElementActive(id);
            }
        })
    })
          
}