import $ from "jquery";
import "slick-carousel";
import "../jquery/jquery.datetimepicker.js";
import "../jquery/jquery.rating.js";
require("malihu-custom-scrollbar-plugin")($);
import { dropDownInit } from "./listners/dropDown";
import { initMasks } from "./helpers/InitMasks";
import {removeAllMarkers} from "./functions/mapHelpers";
import { categoriesMenuScrollListener } from "./listners/categoriesMenuScrollListener.js";

window.$ = $;

export const initInterface = () => {
  $(document).ready(function () {
    // Поставил вдруг пригодится
    $.cookie = function (b, j, m) {
      if (typeof j != "undefined") {
        m = m || {};
        if (j === null) {
          j = "";
          m.expires = -1;
        }
        var e = "";
        if (
          m.expires &&
          (typeof m.expires == "number" || m.expires.toUTCString)
        ) {
          var f;
          if (typeof m.expires == "number") {
            f = new Date();
            f.setTime(f.getTime() + m.expires * 24 * 60 * 60 * 1000);
          } else {
            f = m.expires;
          }
          e = "; expires=" + f.toUTCString();
        }
        var l = m.path ? "; path=" + m.path : "";
        var g = m.domain ? "; domain=" + m.domain : "";
        var a = m.secure ? "; secure" : "";
        document.cookie = [b, "=", encodeURIComponent(j), e, l, g, a].join("");
      } else {
        var d = null;
        if (document.cookie && document.cookie != "") {
          var k = document.cookie.split(";");
          for (var h = 0; h < k.length; h++) {
            var c = $.trim(k[h]);
            if (c.substring(0, b.length + 1) == b + "=") {
              d = decodeURIComponent(c.substring(b.length + 1));
              break;
            }
          }
        }
        return d;
      }
    };

    $.cookie("was", true, {
      expires: 365,
      path: "/",
    });

    $(window).on('load', categoriesMenuScrollListener());


    $(window).on("scroll", function () {
      if ($(window).length > 1024) {
        if ($(".parallax").length) {
          var top = $(window).scrollTop();
          var offsetTop = $("#restaurant").offset().top;
          var parallax = top - offsetTop;

          if (top > offsetTop) {
            if (parallax < 360) {
              $(".parallax").css({ marginTop: -parallax });
              $(".parallax.top").css({ marginTop: -(parallax + 206) });
            }
          }
        }
      }
    });

    $(".open").on("click", function (e) {
      e.preventDefault();
      var id = $(this).attr("href") || $(this).attr("data-modal");
      $(".popup").fadeOut();
      $(id).fadeIn();
      $("body").css({ overflow: "hidden" });
    });

    $(document).delegate(".clear", "click", function () {
      $("#map").remove();
      document.querySelector('html').style.removeProperty("zoom");
      // document.querySelector('html').style.zoom = 0.7026
    });

	let scrollEnd = 0;
	window.addEventListener('scroll', function() {
	  if (window.innerWidth < 1024) {
		const header = document.querySelector('.header-mobile');
		if (scrollEnd > this.scrollY) {
		  header.classList.remove('hide');
		} else {
		  header.classList.add('hide');
		}
		scrollEnd = this.scrollY;
	  }
	});


    function closeMapPopup() {
      let modal = $(this).closest('.popup');
      if (modal.attr('disabled')) {
        return;
      }

      if (window.myMap) {
        removeAllMarkers(window.myMap)
      }

      // $('#searchInput').val('');
      modal.fadeOut();
	  if ($(window).width() > 768) {
		// document.querySelector('html').style.zoom = 0.7026;
	  }
      content.removeAllInputErrors();
      $("body").css({ overflow: "" });
    }


    $(".overlay").on("click", function () {
      closeMapPopup.bind(this)();
    });

    $(".close").on("click", function () {
      closeMapPopup.bind(this)()
    });

    $(document).ready(function () {
      let lang = localStorage.getItem('currentLang') || 'ru';
      lang = lang.toLowerCase();
      $.datetimepicker.setLocale(lang);
      var dateToday = new Date();

      $("#date").datetimepicker({
        i18n: {
          ru: {
            months: [
              "Январь",
              "Февраль",
              "Март",
              "Апрель",
              "Май",
              "Июнь",
              "Июль",
              "Август",
              "Сентябрь",
              "Октябрь",
              "Ноябрь",
              "Декабрь",
            ],
            dayOfWeek: ["S", "M", "T", "W", "T", "F", "S"],
          },
        },
        timepicker: false,
        format: "d.m.Y",
        parentID: ".date-container",
        minDate: dateToday,
      });

      $("#time").datetimepicker({
        datepicker: false,
        format: "H:i",
        allowTimes: [
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
        ].filter(item => Number(item.substring(0, 2)) >= new Date().getHours()),
        parentID: ".time-container",
      });

      $("#birth").datetimepicker({
        i18n: {
          ru: {
            months: [
              "Январь",
              "Февраль",
              "Март",
              "Апрель",
              "Май",
              "Июнь",
              "Июль",
              "Август",
              "Сентябрь",
              "Октябрь",
              "Ноябрь",
              "Декабрь",
            ],
            dayOfWeek: ["S", "M", "T", "W", "T", "F", "S"],
          },
        },
        timepicker: false,
        format: "d.m.Y",
        parentID: ".date-container",
      });
    });

    $(".nav a").on("click", function (e) {
      e.preventDefault();
      var id = $(this).attr("href");

      $(".nav a").removeClass("active");
      $(this).addClass("active");

      $(".tab").removeClass("active");
      $(".tab").hide();

      $(id).fadeIn();
    });

    $(".link button").one("click", function () {
      $("[name='copy']").focus();
      $("[name='copy']").select();
      document.execCommand("copy");
      let text = translate('promocode_is_copied');
      $("[name='copy']").val(text);
    });

    $(".show").on("click", function () {
      $(this).toggleClass("hide");

      if ($(this).is(".hide")) {
        $(".password input").attr("type", "text");
      } else {
        $(".password input").attr("type", "password");
      }
    });

    // $(".buy").on("click", function() {
    //     $(this).hide();
    //     $(this).closest(".product").find(".quantity").fadeIn()
    // });
    //
    // $(".quantity button").on("click", function() {
    //     var input = $(this).closest(".product").find(".quantity input");
    //     var qty = parseInt(input.val());
    //
    //     if ($(this).is(".minus")) {
    //         if (qty > 1) {
    //             qty = qty - 1;
    //         }
    //     } else {
    //         qty = qty + 1;
    //     }
    //
    //     input.val(qty);
    // });

    $("#checkout .radio input").on("change", function () {
      $(".type").hide();
      if ($(this).is(":checked")) {
        $(this).closest(".row").find(".type").fadeIn();
      }
    });

    $("#checkout .delivery select").on("change", function () {
      if ($("#totime").is(":selected")) {
        $("#checkout .checkout .time").fadeIn();
      } else {
        $("#checkout .checkout .time").hide();
      }
    });

    $("#menu .item .btn").on("click", function (e) {
      e.preventDefault();
      var id = $(this).attr("href");

      $(id).fadeIn();
      $("body").css({ overflow: "hidden" });
    });
    $("#category .close").on("click", function () {
      $("#category").fadeOut();
      $("body").css({ overflow: "visible" });
    });

    if ($("#range").length) {
      const _R = document.querySelector("[type=range]");
      _R.style.setProperty("--val", +_R.value);
      _R.style.setProperty("--max", +_R.max);
      _R.style.setProperty("--min", +_R.min);

      document.documentElement.classList.add("js");

      _R.addEventListener(
        "input",
        (e) => {
          _R.style.setProperty("--val", +_R.value);
        },
        false
      );

      $("[name='guestNumber']").val($("#range").val());
    }
    $("#range").on("input", function () {
      $("[name='guestNumber']").val($(this).val());
    });

    $(".burger").on("click", function () {
      $("body").css({ overflow: "hidden" });
      $(".mobile-menu").addClass("active");
    });
    $(".mobile-menu .close").on("click", function () {
      $(".mobile-menu").removeClass("active");
      $("body").css({ overflow: "visible" });
    });

    initMasks();
  });
};


const translate = (key) => {
  let lang = localStorage.getItem('currentLang') || 'ru'
  if (translates[key]) {
    return translates[key][lang.toLowerCase()]
  }
}

const translates = {
  "promocode_is_copied": {
    "ru":"Промокод скопирован!",
    "kz":"Промокод көшірілді!",
    "en":"Promocode is copied!"
  }
}
