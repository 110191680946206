export function dropDownInit ()
{
    window.isDropDownOpened = false;

    $(".dropdown-toggle").on("click", function(e) {
        const isOpened = window.isDropDownOpened;

        e.preventDefault();

        if (isOpened) {
            $(".dropdown-menu").slideUp();
        } else {
            var dropdown = $(this).closest(".dropdown");
            dropdown.find(".dropdown-menu").slideToggle();
        }
    });

    $("body").click(function(e) {
        if ($(e.target).closest(".dropdown-toggle").length === 0) {
            $(".dropdown-menu").slideUp();
        }
    });

    $("#logOut").click(function(e) {
        auth.logOut();
    });
}
