import { checkIfBrowserSafari } from '../functions/checkIfBrowserSafari';

export function categoriesMenuScrollListener () {
  let additionalScale = checkIfBrowserSafari() ? 0.7026 : 1;
    $(window).on("scroll", function () {
        if ($("#categories").length) {
          let categoriesContainer = $("#categories .container");
          let mainMenu = $("#main-menu");
          // console.log(categoriesContainer)
  
          let top = $(window).scrollTop();
          let bottomCategoriesContainer = (categoriesContainer.offset().top + categoriesContainer.outerHeight(true)) * additionalScale;
          let bottomMainMenu = (mainMenu.offset().top + mainMenu.outerHeight(true)) * additionalScale;
          let topMainMenu = mainMenu.offset().top * additionalScale;
          let topCategoriesContainer = categoriesContainer.offset().top * additionalScale;
  
          if (top > topMainMenu) {
            categoriesContainer.addClass("sticky");
          } else if(topMainMenu >= top){
            categoriesContainer.removeClass("sticky");
          }
  
          if(bottomMainMenu <= bottomCategoriesContainer) {
            categoriesContainer.removeClass("sticky");
            categoriesContainer.addClass("sticky-bottom");
          } else if(top <= topCategoriesContainer && categoriesContainer.hasClass('sticky-bottom')){
            categoriesContainer.removeClass("sticky-bottom");
            categoriesContainer.addClass("sticky");
          }
  
        }
    });
}