import {getFormData} from "../functions/getFormData";
import { translate } from "../../views/main";

export function setCateringFormListener(formSelector) {
  const form = $(formSelector);

  if (form) {
    form.submit(function (e) {
      e.preventDefault();
      content.removeAllInputErrors();

      const formData = getFormData(formSelector);

      console.log(formData);

      if (!(formData.phone && formData.phone.length)) {
        return;
      }

      if (!(formData.name && formData.name.length)) {
        return;
      }

      if (!(formData.message && formData.message.length)) {
        return;
      }

      api.getCatering(formData).then(() => {
        content.hideModalWindows(true);
        $(".popup").fadeOut();
        $("#success .modal-content")[0].innerHTML = `
              <h2>${translate('thank_you')}!</h2>
              <span>${translate('will_call_you')}</span>
              <a title="" class="button" onclick="javascript:$('.popup').fadeOut()">${translate('okay') + '!'}</a>
          `;
        $("#success").fadeIn();
      })

    })
  }
}