import {initMasks} from "./InitMasks";

export function validateInputs(action) {
    const { inputs } = DataHelper.store;
    const inputsRes = [];

    if (action === 'login') {
        let phone = inputs.loginPhone;

        phone.value.length === 15 ?
            inputsRes.push(true) :
            inputsRes.push(false) &
            content.setInputError(phone);

        let pass = inputs.loginPass;
        pass.value.length > 1 ?
            inputsRes.push(true) :
            inputsRes.push(false) &
            content.setInputError(pass);
    }

    if (action === 'reg1') {
        let phone = inputs.regPhone;

        phone.value.length === 15 ?
            inputsRes.push(true) :
            inputsRes.push(false) &
            content.setInputError(phone);

        let pass = inputs.regPass;
        pass.value.length > 1 ?
            inputsRes.push(true) :
            inputsRes.push(false) &
            content.setInputError(pass);

        let name = inputs.regName;
        name.value.length > 1 ?
            inputsRes.push(true) :
            inputsRes.push(false) &
            content.setInputError(name);
    }

    if (action === "review") {
        let phone = inputs.reviewFormPhoneInput;
        phone.value.length === 15 ?
          inputsRes.push(true) :
          inputsRes.push(false) &
          content.setInputError(phone);

        let name = inputs.reviewFormNameInput;
        name.value.length > 1 ?
          inputsRes.push(true) :
          inputsRes.push(false) &
          content.setInputError(name);

        let message = inputs.reviewFormMessageInput;
        message.value.length > 1 ?
          inputsRes.push(true) :
          inputsRes.push(false) &
          content.setInputError(message);
    }

    if (action === "reservation") {
        let phone = inputs.reservationPhone;
        phone.value.length === 15 ?
          inputsRes.push(true) :
          inputsRes.push(false) &
          content.setInputError(phone);

        let restaurant = inputs.reservationRestaurant;
        restaurant.value ?
          inputsRes.push(true) :
          inputsRes.push(false) &
          content.setInputError(restaurant);

        let date = inputs.reservationDate;
        date.value ?
          inputsRes.push(true) :
          inputsRes.push(false) &
          content.setInputError(date);

        let time = inputs.reservationTime;
        time.value ?
          inputsRes.push(true) :
          inputsRes.push(false) &
          content.setInputError(time);

    }

    initMasks();
    return inputsRes.every((el)=> el === true);
}
