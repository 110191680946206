export function setLangChangeListener ()
{
    [...document.querySelectorAll("#langs a")].forEach((a) => {
        a.addEventListener("click", (e) => {
            e.preventDefault();
            const lang = a.getAttribute("data-lang");
            localStorage.setItem("currentLang", lang);
            location.reload();
        });
    });
}