import { setCityRestaurants } from "./setCityRestaurants";
import { initReservationModal } from "./initReservationModal";
import { dropDownInit } from "../listners/dropDown";
import { initInterface } from "../initInterface";

import { translate } from '../../views/main'
import { setLangChangeListener } from "../listners/setLangChangeListener";
import { setCityChangeListener } from "../listners/setCityChangeListener";
import { headerMenuScrollListener } from "../listners/headerMenuScrollListener";

export function resolveTopBar(onCityChange) {
  content.setLangs()
  return api.getRestaurantList().then(({ restaurants }) => {
    return api.getCities().then(({ cities }) => {
      content.setTopCities(cities, (changedCity) => {
        setCityRestaurants(restaurants, cities[0]);
        onCityChange && onCityChange(({ changedCity, restaurants }));
      })

      $("#topbar_navigation").html(`
        <li><a href="/index.html" data-langkey="delivery" title="" class="${location.pathname === "/index.html" ? "active" : ""}" >${translate('delivery')}</a></li>
        <li><a href="/delivery.html" data-langkey="delivery" title="" class="${location.pathname === "/delivery.html" ? "active" : ""}" >${translate('deliv_price')}</a></li>
        <li><a href="/about.html" data-langkey="restaurant" title="" class="${location.pathname === "/about.html" ? "active" : ""}">${translate('about_us')}</a></li>
        <li>
          <a href="#reservation-modal" data-langkey="reserv_tables" id="topBarReservationButton" title="" class="topBar open">${translate('reserv')}</a>
        </li>
        <li><a href="/catering.html" data-langkey="catering" title="" class="${location.pathname === "/catering.html" ? "active" : ""}">${translate('catering')}</a></li>
        <li><a href="/promotions.html" data-langkey="promo" title="" class="${location.pathname === "/promotions.html" ? "active" : ""}">${translate('promotions')}</a></li>
        <li><a href="/contacts.html" data-langkey="restaurants" title="" class="${location.pathname === "/contacts.html" ? "active" : ""}">${translate('restaurants')}</a></li>
      `);

      $("#sidebar_navigation").html(`
        <li><a href="/index.html" data-langkey="delivery" title="" class="${location.pathname === "/index.html" ? "active" : ""}" >${translate('delivery')}</a></li>
        <li><a href="/delivery.html" data-langkey="delivery" title="" class="${location.pathname === "/delivery.html" ? "active" : ""}" >${translate('deliv_price')}</a></li>

        <li><a href="/about.html" data-langkey="restaurant" title="" class="${location.pathname === "/about.html" ? "active" : ""}">${translate('about_us')}</a></li>
        <li>
          <a href="#reservation-modal" data-langkey="reserv_tables" id="topBarReservationButton" title="" class="topBar open">${translate('reserv')}</a>
        </li>
        <li><a href="/catering.html" data-langkey="catering" title="" class="${location.pathname === "/catering.html" ? "active" : ""}">${translate('catering')}</a></li>
        <li><a href="/promotions.html" data-langkey="promo" title="" class="${location.pathname === "/promotions.html" ? "active" : ""}">${translate('promotions')}</a></li>
        <li><a href="/contacts.html" data-langkey="restaurants" title="" class="${location.pathname === "/contacts.html" ? "active" : ""}">${translate('restaurants')}</a></li>
      `)

      const blockTopbarMenu = document.querySelector('#topbar_menus');
      const cartList = JSON.parse(localStorage.getItem("cart")) || [];
      const summaryProducts = cartList.reduce(
        (sum, current) => {
          sum.total += Number(current.count);
          sum.price += current.count * Number(current.price);
          return sum;
        },
        { total: 0, price: 0 }
      );


      const topbarMenuWrapper = `
        <a id="products-summary" href="/checkout.html" title="" class="cart ${cartList.length && "cart-filled"}">
          ${summaryProducts.total} ${translate('dish_for')} 
          ${summaryProducts.price} ${'₸'} 
        </a>`;

      if (document.querySelector("#products-summary")) {
        document.querySelector("#products-summary").remove()
      }
      blockTopbarMenu.innerHTML = blockTopbarMenu.innerHTML + topbarMenuWrapper;

      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.has('city')) {
        const id = queryParams.get('city')
        const city = cities.filter(c => c.uuid === id)[0]
        content.setTopCityInfo(city.name, city.phone)
        localStorage.setItem('currentCity', JSON.stringify(city))
      }

      const adaptiveCitiesBlock = document.querySelector("#cities-adaptive");
      const currentCity = JSON.parse(localStorage.getItem("currentCity")) || cities[0];
      adaptiveCitiesBlock ? adaptiveCitiesBlock.innerHTML = `
              <button class="dropdown-toggle city-name">
              ${currentCity && currentCity.name}
              </button>
              <ul class="dropdown-menu">
                  ${cities
          .map(
            (city) => `
                                <li><a data-uuid="${city.uuid}" class="${city.uuid === currentCity.uuid ? "active" : ""
              }">${city.name
              }</a></li>  
                            `
          )
          .join("")}
              </ul>
          ` : null;

      initReservationModal();
      initInterface();
      dropDownInit();
      setCityRestaurants(restaurants, cities[0]);
      setLangChangeListener();
      setCityChangeListener(cities);
      headerMenuScrollListener();




      return { restaurants, baseCity: cities[0] };
    });
  });
}