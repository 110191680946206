import { api }  from '../../plugins/axios'
import { apiConfig } from '../../config';
import { formatPhoneNumber } from "../utils";
import { getUserToken ,setUserToken, removeUserToken } from "./Token";

export class Auth {
    constructor() {
        this.userIsAuth = false;

        this.checkUserStatus();
    }

    checkUserStatus() {
        const token = getUserToken();

        if (token && token !== 'undefined') {
            const payload = {
                type: "users",
                action: "checkSession",
                session: token
            };

            return api.post(null, payload).then((data) => {
                this.userIsAuth = true;

                return data;
            });
        } else {
            removeUserToken();
        }
    }

    auth(user) {
        const payload = {
            type: "users",
            action: "authorization", 
            login:  formatPhoneNumber(user.login),
            password: user.password,
            firebaseid: apiConfig.firebaseid
        };

        return api.post(null, payload).then(({data}) =>{
            setUserToken(data.session);

            return data;
        });
    }

    register(user, step) {
        const payload = {};

        if (step === 1) {
            payload.type = "users";
            payload.action = "registration1";
            payload.phone = formatPhoneNumber(user.login);
            payload.password = user.password;
        } 
        else if (step === 2) {
            payload.type = "users";
            payload.action = "registration2";
            payload.session = getUserToken();
            payload.smscode = user.smscode;
            payload.name = user.name;
            payload.password = user.password;
            payload.firebaseid = apiConfig.firebaseid;
        }

        return api.post(null, payload).then(({data}) => {
            if (step ===1) {
                setUserToken(data.session);
            }
            if (step === 2) {
                 location.assign('/');
            }
            return data;
        });
    }

    logOut() {
        const token = getUserToken();
        const payload = {
            type: "users",
            action: "logout", 
            session: token
        };

        return api.post(null, payload).then((data) => {
            removeUserToken();
            localStorage.removeItem('cart');
            location.assign('/index.html');

            return data;
        });
    }
}
