export function activateRegSmsCounter() {
    const regSmsCounter = document.querySelector('#regSmsCounter');
    const regSmsField = document.querySelector('#regSmsField');
    const regSmsCounterButton = document.querySelector('#regSmsCounterButton');


    if(regSmsCounter.innerHTML !== '(60)'){
        return;
    }

    regSmsField.removeAttribute('hidden');
    regSmsCounterButton.setAttribute('hidden', true);

    let i = 60;
    const interval = setInterval(() => {
        i--;
        regSmsCounter.innerHTML = `(${i})`;

        if (i === 0) {
            document.querySelector('#regSmsField').setAttribute('hidden', true);
            regSmsCounterButton.removeAttribute('hidden');
            clearInterval(interval);
        }
    }
    , 1000);
}
