import {initInterface} from '../src/initInterface';
import {GetDataHelper} from '../src/helpers/GetDataHelper';
import {initGlobalStore} from '../src/store';
import { translate } from './main';

import {Auth} from '../src/api/Auth';
import {ContentManager} from "../src/helpers/ContentManager";
import {setAuthRegButtonListeners} from "../src/listners/setAuthRegButtonListeners";
import {Api} from "../src/api/Api";
import $ from "jquery";
import {setReviewFormListener} from "../src/listners/setReviewFormListener";
import {resolveTopBar} from "../src/functions/resolveTopBar";
import {setCateringFormListener} from "../src/listners/setCateringFormListener";

window.content = new ContentManager();
window.auth = new Auth();
window.DataHelper = new GetDataHelper();
window.api = new Api();

// initInterface();
initGlobalStore();

setAuthRegButtonListeners(() => location.reload());
setReviewFormListener();

function main() {

    let currentCity = localStorage.getItem('currentCity')
    if (!currentCity) {
    }

    Promise.all([api.getUserProfile(), api.getUsersBonusBalance()]).then(() => {
        content.setProfileHeader(DataHelper.store.user, DataHelper.store.bonuses);
        content.hideModalWindows(false);
    })
        .catch(error => console.log("error on auth check: ", error))
        .finally(() => {
            setPage();
        });
}

function setPage() {
    resolveTopBar(() => location.reload()).then(({baseCity}) => {
        const city = JSON.parse(localStorage.getItem('currentCity')) || baseCity;
        const lang = localStorage.getItem('currentLang') || 'ru';

        api.getCityMenu(city.uuid, lang).then(async ({menuitems}) => {
            
            const menu = await content.setMenusContent(menuitems)
            const allDishes = Object.values(menu).map(({items}) => items).reduce((acc, cur) => {
                return acc.concat(cur);
            }, []);
            addMenuListeners(city.uuid, allDishes)
        });
        api.getBanners(lang).then(({stocks}) => {
            content.setBanners(stocks);
        });

        setCateringFormListener("#catering-form");
    })

}

function initSlider() {
    $(".slides").slick({
        dots: true,
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        /*autoplay: true,*/
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                }
            }
        ]
    });

    if ($(window).width() > 736) {
        $(".scroll-x").mCustomScrollbar({
            axis: "x",
            callbacks: {
                onScrollStart: function () {
                    var top = $("#gallery").offset().top;
                    $("body, html").animate({scrollTop: top + 200}, "fast");
                },
            }
        });
    } else {
        $(".scroll-x").slick({
            dots: false,
            arrows: false,
            centerMode: true,
            infinite: false
        });
    }
}


const setMainProductCardButtons = (dish, allDishes) => {

    let dishId = dish.uuid
    let productCard = document.querySelector(`.product[data-id='${dishId}']`)
    let buttonsContainer = productCard.querySelector('.buttons')
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const dishInCart = cart.find(c => c.id === dishId);
    console.log(dishId)
    if (dishInCart) {
        buttonsContainer.innerHTML = `
          <div class="quantity">
              <button class="minus" data-id="${dishId}">-</button>
              <input type="text" name="quantity" value="${dishInCart.count}" readOnly/>
              <button class="plus" data-id="${dishId}">+</button>
          </div>
        `
    } else {
        buttonsContainer.innerHTML = `
          <button 
            class="buy" 
            data-price="${dish.sale ? dish.saleprice : dish.price}" 
            data-id="${dish.uuid}"
          >
              Добавить
          </button>
        `
    }

    function plusButtonFunc(event) {
        const cartList = JSON.parse(localStorage.getItem("cart")) || [];

        console.log('cartList', cartList);

        const dish = allDishes.find(d => d.uuid === dishId);
        const dishInCart = cartList.find(c => c.id === dishId);
        const incrementedValue = dishInCart.count + 1;
        const newCart = cartList.filter(c => c.id !== dishId).concat([{...dishInCart, count: incrementedValue}]);

        console.log('newCart', newCart);


        localStorage.setItem("cart", JSON.stringify(newCart));
        const parentElement = event.target.parentElement;
        parentElement.children[1].value = incrementedValue;

        content.setSummaryProductsButton(newCart);
    }


    function minusButtonFunc(event) {

        const cartList = JSON.parse(localStorage.getItem("cart")) || [];
        const dish = allDishes.find(d => d.uuid === dishId);
        const dishInCart = cartList.find(c => c.id === dishId);
        const decrementedValue = dishInCart.count - 1;
        const filteredCart = cartList.filter(c => c.id !== dishId);

        console.log('decrementedValue', decrementedValue);

        const newCart = decrementedValue > 0 ? filteredCart.concat([{
            ...dishInCart,
            count: decrementedValue
        }]) : filteredCart;

        const parentElement = event.target.parentElement;
        const buttonsClassElement = parentElement.parentElement;

        if (decrementedValue > 0) {
            parentElement.children[1].value = decrementedValue;
        } else {
            buttonsClassElement.innerHTML = `
                <button
                    data-id="${dish.uuid}"
                    data-price="${dish.sale ? dish.saleprice : dish.price}" 
                    class="buy"
                >
                    Добавить
                </button>
                `;

            buttonsClassElement.querySelector('.buy').addEventListener("click", mainCardBuyButton);
        }

        localStorage.setItem("cart", JSON.stringify(newCart));
        content.setSummaryProductsButton(newCart);
    }


    function mainCardBuyButton(event) {
        const productPrice = event.currentTarget.getAttribute("data-price");
        const dishId = event.currentTarget.getAttribute("data-id");
        const cartList = JSON.parse(localStorage.getItem("cart")) || [];

        let updatedList = [...cartList];

        const hasProduct = updatedList.find((item) => item.id === dishId);

        if (hasProduct) {
            const newList = updatedList.map((item) => {
                if (item.id === dishId) {
                    item.count++;
                }

                return item;
            });
            localStorage.setItem("cart", JSON.stringify(newList));
        } else {
            const payload = {
                id: dishId,
                price: productPrice,
                count: 1,
            };

            updatedList.push(payload);

            localStorage.setItem("cart", JSON.stringify(updatedList));
        }

        const parentElement = event.target.parentElement;

        parentElement.innerHTML = `
            <div class="quantity">
                <button class="minus" data-id="${dishId}">-</button>
                <input type="text" name="quantity" value="1" readOnly/>
                <button class="plus" data-id="${dishId}">+</button>
            </div>
            `;


        parentElement.querySelector(".minus").addEventListener("click", minusButtonFunc);
        parentElement.querySelector(".plus").addEventListener("click", plusButtonFunc);

        content.setSummaryProductsButton(updatedList);
    }


    buttonsContainer.querySelector('.buy').addEventListener("click", mainCardBuyButton);

    buttonsContainer.querySelector(".minus").addEventListener("click", minusButtonFunc);
    buttonsContainer.querySelector(".plus").addEventListener("click", plusButtonFunc);

}


const minusButtonListener = (event, allDishes = []) => {

    const dishId = event.target.getAttribute("data-id");

    console.log('cart', cart);


    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const dishInCart = cart.find(c => c.id === dishId);
    const dish = allDishes.find(d => d.uuid === dishId);
    const decrementedValue = dishInCart.count - 1;
    const filteredCart = cart.filter(c => c.id !== dishId);

    console.log('decrementedValue', decrementedValue);

    const newCart = decrementedValue > 0 ? filteredCart.concat([{
        ...dishInCart,
        count: decrementedValue
    }]) : filteredCart;

    const parentElement = event.target.parentElement;
    const buttonsClassElement = parentElement.parentElement;


    if (decrementedValue > 0) {
        parentElement.children[1].value = decrementedValue;
    } else {
        buttonsClassElement.innerHTML = `
          <button
            data-id="${dish.uuid}"
            data-price="${dish.sale ? dish.saleprice : dish.price}" 
            class="button buy_card"
          >
            Добавить в корзину
          </button>
        `;

        buttonsClassElement.querySelector('.buy_card').addEventListener("click", event => addProductButtonListener(event, allDishes));
    }

    localStorage.setItem("cart", JSON.stringify(newCart));
    content.setSummaryProductsButton(newCart);
    // setMainProductCardButtons(dish, allDishes);
}

const plusButtonListener = (event, allDishes) => {
    const dishId = event.target.getAttribute("data-id");
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const dish = allDishes.find(d => d.uuid === dishId);
    const dishInCart = cart.find(c => c.id === dishId);
    const incrementedValue = dishInCart.count + 1;
    const newCart = cart.filter(c => c.id !== dishId).concat([{...dishInCart, count: incrementedValue}]);
    localStorage.setItem("cart", JSON.stringify(newCart));
    const parentElement = event.target.parentElement;
    parentElement.children[1].value = incrementedValue;

    content.setSummaryProductsButton(newCart);
    // setMainProductCardButtons(dish, allDishes);
}

const addProductButtonListener = (event, allDishes) => {

    console.log('BUY button clicked');

    const productPrice = event.currentTarget.getAttribute("data-price");
    const dishId = event.currentTarget.getAttribute("data-id");
    const dish = allDishes.find(d => d.uuid === dishId);
    const cartList = JSON.parse(localStorage.getItem("cart")) || [];

    let updatedList = [...cartList];

    const hasProduct = updatedList.find((item) => item.id === dishId);

    if (hasProduct) {
        const newList = updatedList.map((item) => {
            if (item.id === dishId) {
                item.count++;
            }

            return item;
        });
        localStorage.setItem("cart", JSON.stringify(newList));
    } else {
        const payload = {
            id: dishId,
            price: productPrice,
            count: 1,
        };

        updatedList.push(payload);

        localStorage.setItem("cart", JSON.stringify(updatedList));
    }

    const parentElement = event.target.parentElement;

    parentElement.innerHTML = `
        <div class="quantity">
            <button class="minus_card" data-id="${dishId}">-</button>
            <input type="text" name="quantity" value="1" readOnly/>
            <button class="plus_card" data-id="${dishId}">+</button>
        </div>
        `;

    parentElement.querySelector(".minus_card").addEventListener("click", event => minusButtonListener(event, allDishes));
    parentElement.querySelector(".plus_card").addEventListener("click", event => plusButtonListener(event, allDishes));

    content.setSummaryProductsButton(updatedList);
}


function addMenuListeners(cityId, allDishes = []) {
    document.querySelector('#main-menu')
        .addEventListener('click', (e) => {
            const target = e.target;
            console.log('target.tagName', target.tagName);
            console.log('OPEN MODAL NO');
            if (target.tagName === 'A' || target.tagName === 'IMG') {
                const parent = e.target.closest('.product')
                const id = parent.getAttribute('data-id')
                console.log('parent', parent);
                const lang = localStorage.getItem('currentLang') || 'ru';
                api.getDishId(id, cityId, lang).then(({menuitem}) => {
                    setModalProduct(menuitem, allDishes)
                    $('#product-modal').fadeIn()

                    $(".buy_card").on("click", function (event) {
                        addProductButtonListener(event, allDishes)
                        $(this).hide();
                        $(this).closest(".product").find(".quantity").fadeIn()
                    });

                    $('.minus_card').on("click", event => minusButtonListener(event, allDishes))
                    $('.plus_card').on("click", event => plusButtonListener(event, allDishes))
                })

            }
        })
}

function setModalProduct(item, allDishes) {

    console.log('ITEM', item);
    
    const renderTimeCartState = JSON.parse(localStorage.getItem("cart")) || [];
    const dishId = item.uuid;
    const dish = allDishes.find(d => d.uuid === dishId);
    const dishInCart = renderTimeCartState.find(c => c.id === dishId);
    console.log(allDishes, dish)
    let buttons = `
        <button
            data-id="${dish.uuid}"
            data-price="${dish.sale ? dish.saleprice : dish.price}" 
            class="button buy_card"
        >
            ${translate('add_to_cart')}
        </button>
  `

    if (dishInCart) {
        buttons = `
            <div class="quantity">
                <button class="minus_card" data-id="${dishId}">-</button>
                <input type="text" name="quantity" value="${dishInCart.count}" readOnly/>
                <button class="plus_card" data-id="${dishId}">+</button>
            </div>
            `
    }

    let popup = document.querySelector('#product-modal')
    popup.style.display = 'block'
    popup.querySelector('.modal').innerHTML = `
            <div class="image">
            <div class="properties">
            <span class = "property-1"></span>
            <span class="property-2"></span>
            <span class="property-3"></span>
            </div>
            <img src="${item.image}" alt="" />
            ${item.info
        ? `<div class="tooltip">
                    <a href="#" title=""></a>
                    <div class="tooltip-box">
                    <div class="title">Пищевая ценность на 100 г.</div>
                    <ul>
                    <li>Энерг. ценность<span>253,4 ккал</span></li>
                    <li>Белки<span>9,4 г</span></li>
                    <li>Жиры<span>9,4 г</span></li>
                    <li>Углеводы<span>9,4 г</span></li>
                    <li>Вес<span>9,4 г</span></li>
                    </ul>
                    <div class="description">Может содержать аллергены: аспартам, горчица, диоксид серы и сульфиты, кунжут, моллюски, орехи, ракообразные, рыба, сельдерей, соя, яйца</div>
                    </div>
                    </div>`
        : ''}
            </div>
            <div class="flex ai-c">
            <div class="product-name">${item.name}</div>
            <span class="mass">${item.weight ? '• ' + item.weight : ''}</span>
            </div>
            <div class="description">${item.description}</div>
            <div class="price">
            <span class="new-price">${item.price}</span>
            </div>
            <div class="type flex wrap">
            <label>
            <input type="radio" name="type" value="Не острое" checked="checked" />
            <span>${translate('not_spicy')}</span>
            </label>
            <label>
            <input type="radio" name="type" value="Среднее" />
            <span>${translate('mid_spicy')}</span>
            </label>
            <label>
            <input type="radio" name="type" value="Острое" />
            <span>${translate('spicy')}</span>
            </label>
            </div>
            <div class="buttons">
            ${buttons}
            </div>
        `
}

main();