import { toast } from "../../plugins/toast";
import $ from "jquery";
import { getUserToken } from "../api/Token";
import axios from "axios";
import { Api } from "../../src/api/Api";
import { translate } from "../../views/main";
import { headerMenuScrollListener } from "../listners/headerMenuScrollListener";
import { categoriesMenuSelectListener } from "../listners/categoriesMenuSelectListener";

export class ContentManager {
  setToastMessage(isWarn, message) {
    const currentTime = Math.floor(Date.now() / 1000);
    const localLastTimeToast =
      document.lastTimeToast != null ? document.lastTimeToast : currentTime - 5;

    if (currentTime - localLastTimeToast >= 3) {
      toast(isWarn, message);
    }
  }


  setProfileHeader(user, bonuses) {
    const block = document.querySelector("#headerContent");
    block.innerHTML = `
       <div class="account dropdown">
            <div class="dropdown-toggle">
            <div class="profile">
            <div class="name">${user.name}</div>
            <div class="coins">${(bonuses && bonuses.balance) || 0}</div>
            <input class="user_bonuses" value="${(bonuses && bonuses.balance) || 0}" id="user_bonuses" name="user_bonuses" hidden/>
            </div>
            </div>
            <ul class="dropdown-menu">
            <li><a href="/profile.html" title="" data-translate="my_profile">${translate('my_profile')}</a></li>
            <li><span id="logOut" title="" data-translate="quit">${translate('quit')}</span></li>
            </ul>
        </div>
        `;
  }

  setProfileInputs(user) {
    const { inputs } = DataHelper.store;
    const userBirthDay = user.birthday ? new Date(user.birthday) : "";
    inputs.profileName.value = user.name || "";
    inputs.profilePhone.value = user.phone || "";
    inputs.profileBirthDate.value = userBirthDay ? `${userBirthDay.getDate()}.${(userBirthDay.getMonth() + 1) < 10 ? '0' + (userBirthDay.getMonth() + 1) : (userBirthDay.getMonth() + 1)}.${userBirthDay.getFullYear()}` : "";
    inputs.profileGender.value = (user.gender === 'Мужской')
      ? translate('profile_gender_male')
      : (user.gender === 'Женский')
        ? translate('profile_gender_female')
        : "";
    inputs.profilePromocode.value = user.promocode || "";

    if (user.birthday) {
      inputs.profileBirthDate.disabled = true;
    };
    if (user.gender) {
      inputs.profileGender.disabled = true;
    };
    if (user.phone) {
      inputs.profilePhone.disabled = true;
    };
  }

  showSmsModal(isReg) {
    const { buttons } = DataHelper.store;
    const { inputs } = DataHelper.store;

    $(".popup").fadeOut();
    $("#code").fadeIn();
    $("body").css({ overflow: "hidden" });

    const phone = inputs.restorePassPhone.value;
    const regSmsNumber = document.querySelector("#regSmsNumber");
    regSmsNumber.innerHTML = `${translate('we_send_code_on_number')} ${phone}`;

    if (!isReg) {
      buttons.regButtonStepTwoApprove.onclick = function (e) {
        e.preventDefault();

        const code = DataHelper.store.inputs.regSmsCode.value;

        if (code.length > 3) {
          content.showResPassModal();
        } else {
          content.setToastMessage(true, "Пожалуйста, заполните все поля!");
        }
      };
    }
  }

  setProfileBonuses(bonusData) {
    const bonusCountBlock = document.querySelector("#bonusCounter");
    // const bonusesDisplayBlock = document.querySelector("#discounts");

    if (bonusData) {
      const balance = parseInt(bonusData.balance) || 0;
      const level = parseInt(bonusData.level) || 5;
      const levels = bonusData.bonuslevels;

      const currentIndex = levels.findIndex((lvl) => lvl.level === level);
      const maxLevel = levels.reduce((a, b) => (a.level > b.level ? a : b));

      // if (level === 20) {
      //   bonusesDisplayBlock.innerHTML = `
      //               <div class="discounts special-client">
      //                   20 %
      //               </div>
      //           `;
      //
      //   return;
      // }
      //
      // if (levels[currentIndex].level !== maxLevel.level) {
      //   const nextLevel = levels[currentIndex + 1];
      //   const progressPercent = (balance / nextLevel.sum).toFixed(2) * 100;
      //
      //   bonusesDisplayBlock.innerHTML = `
      //               <div class="discounts">
      //                   <div class="discount active">${level || 5}%</div>
      //                   <div class="progress active"><span>${balance} ₸</span></div>
      //                   <div class="percent active">${Math.round(
      //                     progressPercent
      //                   )}%</div>
      //                   <div class="progress"><span>${
      //                     nextLevel.sum - balance
      //                   } ₸</span></div>
      //                   <div class="discount">${parseInt(
      //                     nextLevel.level
      //                   )}%</div>
      //                   <div class="progress end"></div>
      //               </div>
      //           `;
      // } else {
      //   const prev = levels[levels.length - 2];
      //   bonusesDisplayBlock.innerHTML = `
      //               <div class="discounts">
      //                   <div class="discount">${prev.level}%</div>
      //                   <div class="progress active"></div>
      //                   <div class="discount active">${parseInt(
      //                     maxLevel.level
      //                   )}%</div>
      //               </div>
      //           `;
      //   document.querySelector(".progress.active").style.width = "70%";
      // }

      bonusCountBlock.innerHTML = `<span data-translate="bonus_letter">${translate('bonus_letter')}</span> ${bonusData && bonusData.balance ? bonusData.balance : 0
        }`;

      let bonusPercent = Math.round(bonusData.bonuslevel);
      $('#discount__perc')[0].innerText = bonusPercent + '%';
      $('.discount__sum')[0].innerHTML = `${bonusData.tothenextbonuslevel} ${translate('tenge_alias')}`;
    }
  }

  setProfileOrderHistory(orders) {
    const orderHistoryContainer = $("#profile_orderHistoryContainer");

    const orderItems = orders.map((o) => `
      <div class="item">
        <div class="flex jc-sb ai-c">
          <h3 class="done" data-translate="order_number">${translate('order_number')}${o.docnumber}</h3>
          <h6>${new Date(o.docdate).toLocaleDateString("ru-Ru")}, ${o.doctime}</h6>
        </div>
        <h5>
          <span class="green">+${o.bonusplus || 0} ${translate('bonus_letter')}</span
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="red"
            >-${o.bonusminus || 0} ${translate('bonus_letter')}</span
          >
        </h5>
        <div class="flex jc-end var dateToday = new Date();">
          <div class="buttons">
            <a href="#tab-2-1" title="" data-order-id="${o.uuid}" class="btn detail" data-translate="more_detailed">${translate('more_detailed')}</a>
            <button id="repeatOrder" data-order-id="${o.uuid}" class="btn" data-translate="repeat">${translate('repeat')}</button>
          </div>
        </div>
      </div>
    `).join("");

    orderHistoryContainer.html(`
      ${orderItems}
    `)

    $(".detail").click(function (e) {
      const orderId = $(this).data("orderId");
      const currentLang = localStorage.getItem("currentLang") || 'RU';

      api.getOrder(orderId, currentLang).then((order) => {

        $("#tab-2-1").html(`
        <div class="history">
          <div class="title">
            <a href="#tab-2" title="" class="back"></a>
            <h3 data-translate="order_number">${translate("order_number")}${order.docnumer}</h3>
          </div>
          <div class="items">
            ${order.dishes.map(dish => `
                <div class="product">
                  <div class="flex ai-c">
                    <a href="#product" title="" class="image open">
                        <img src="${dish.image}" alt="">
                    </a>
                    <div class="description">
                  <h5>
                    <a href="#product" title="" class="open">${dish.name || ""}</a>
                   </h5>
                  <h6>${dish.description || ""}</h6>
                    </div>
                    <h5>${dish.price} ₸ <span>x ${dish.quantity}</span></h5>
                  </div>
                </div>  
              `)
          }
          <div class="totals">
            <ul>
              <li>
                <span>${translate('cart')}</span>
                <span>
                 ${order.dishes.reduce((acc, cur) =>
            acc + (cur.price * cur.quantity), 0)} ₸
                </span>
              </li>
              <li><span>${translate('cashback')}</span><span>0 ₸</span></li>
              <li>
                <span>${translate('total')}</span>
                <span>
                  ${order.dishes.reduce((acc, cur) =>
              acc + (cur.price * cur.quantity), 0)} ₸
                </span>
              </li>
            </ul>
            <button id="repeatOrderSinglePage" class="btn">${translate('repeat_order')}</button>
          </div>
        </div>
      `)
        $('#repeatOrderSinglePage').click(repeatOrder);

        function repeatOrder() {
          localStorage.setItem("cart", JSON.stringify(
            order.dishes.map(item => {
              return {
                id: item.uuid,
                price: item.price,
                count: item.quantity
              }
            })));
          window.location.href = "/checkout.html";
        }

        $(".back").click(function (e) {
          $("#tab-2-1").fadeOut();
          $("#tab-2").fadeIn();
        })

        $(".tab").hide();
        $("#tab-2-1").fadeIn();
      });
      console.log({ orderId })
    })

    document.querySelectorAll("#repeatOrder").forEach((el) => {
      el.addEventListener('click', event => {
        const orderId = el.dataset.orderId;
        const currentLang = localStorage.getItem("currentLang") || 'RU';
        api.getOrder(orderId, currentLang).then((order) => {
          localStorage.setItem("cart", JSON.stringify(
            order.dishes.map(item => {
              return {
                id: item.uuid,
                price: item.price,
                count: item.quantity
              }
            })));
          window.location.href = "/checkout.html";
        })
      });
    })
  }


  setWishItems(wishes) {
    const wishContainer = $("#wishlist");

    const wishItems = wishes.map((w) => `
    <div class="product" data-id="${w.uuid}">
      <button class="wishlist" onClick="deleteWish('${w.uuid}')"></button>
      <div class="flex ai-c">
        <a href="#product" title="" class="image">
          <img src="${w.image}" alt="">
        </a>
        <div class="description">
          <h5><a href="#product" title="" class="">${w.name}</a></h5>
          <h6>${w.folder_name}</h6>
        </div>
      </div>
      <div class="flex jc-sb ai-c">
          <div class="price">
              ${w.sale ? `<span class="old-price">${w.price} ₸</span>` : ""}
              
              <span class="new-price">${w.sale ? w.saleprice : w.price} ₸</span>
          </div>
          <div class="buttons" data-id="${w.folder_uuid}">
          </div>
      </div>
    </div>
    `).join("");

    wishContainer.html(`
      <div class="items">
        ${wishItems}
      </div>
    `);

  }


  setProfileAddresses(addresses) {
    const addressesContainer = $("#profile_addressesContainer");
    const city = JSON.parse(localStorage.getItem('currentCity'));
    const addressItems = addresses.map((a) => `
      <div class="col">
        <div class="address">
          <div class="flex jc-sb ai-c">
            <div>
              <h5>${a.address}</h5>
              <small>${a.entrance} ${translate('entrance')}, ${translate('flat')} ${a.apartment}</small>
            </div>
            <button onclick="deleteAddress('${a.uuid}')" data-uuid="${a.uuid}" title="" class="delete"></button>
          </div>
        </div>
      </div>
    `).join("");


    let current_city = JSON.parse(localStorage.getItem("currentCity"));
    if (current_city.delivery) {
      addressesContainer.html(`
        <div class="col">
          <a href="#addAddress" id="openAddress" title="" class="btn" data-translate="add_address"
          >${translate('add_address')}</a
          >
        </div>
        ${addressItems}
      `);
    }

    $("#openAddress").on("click", function (e) {
      e.preventDefault();

      $(".popup").fadeOut();
      $("#addAddress").fadeIn();
      $("body").css({ overflow: "hidden" });
    });
  }

  setCheckoutAddressAndDeliveryTimeFields(addresses, deliveryTimes) {
    $("#addressesAndDeliveryTimesSelect").html(`
     <div style="width: 50%; margin-right: 8px;">
      <label class="field">
        <small>${translate('delivery_address')}</small>
        <select id="addressesSelect" name="address">
          ${addresses.map((a) => `
            <option value="${a.uuid}" data-price="${a.price}" data-freefrom=${a.freefrom}>${a.address}</option>
          `)}
          <option disabled selected value> ${translate('choose_an_address')} </option>
        </select>
        <input id="addressesSelectHidden" value="" type="hidden" name="addressId" autocomplete="off">
        <input id="freefrom" value="" type="hidden" name="freefrom" autocomplete="off">
        <button type="button" class="addAddressBtn">${translate('address')}</button>
        <!--<select id="addressesSelect" name="addressId">
          <option value=undefined>${translate('choose_an_address')}</option>
          <option value="addAddress" style="background-color: #efefef;">
            ${translate('add_an_address')}</a>
          </option>
        </select>-->
      </label>
      <div class="error__mesage" hidden>
        ${translate('choose_an_address')}
      </div>
     </div>
      
     <div style="width: 50%; margin-left: 7px;">
       <label class="field select">
         <small>${translate('deliver_when')}</small>
         <select id="deliveryTimesSelect" name="deliverytimes">
           ${deliveryTimes.map((t, idx) => `
             <option value="${t}">
              ${(idx === 0)
        ? translate('asap')
        : (idx === 1)
          ? translate('in_an_hour')
          : (idx === 2)
            ? translate('in_two_hours')
            : ''
      }
            </option>
           `)}
         </select>
       </label>
     </div>
      
    `);

    let currentCity = JSON.parse(localStorage.getItem('currentCity')) || {}

    api.getPolygonsCity(currentCity.uuid)
      .then(data => {
        console.log('data', data.polygons.map(
          (item) => (
            {
              coords: item.polygon,
              price: item.price
            }
          )
        )
        )
      })


    $("#openAddress").on("click", function (e) {
      e.preventDefault();

      $(".popup").fadeOut();
      $("#addAddress").fadeIn();
      $("body").css({ overflow: "hidden" });
    });

    $(".addAddressBtn").click(function (e) {
      $(".popup").fadeOut();
      $("#addAddress").fadeIn();
      $("body").css({ overflow: "hidden" });
    });

    $("#addressesSelect").on("change", function () {
      $("#addressesSelectHidden").val($("#addressesSelect").val());
      let price = event.target.options[event.target.selectedIndex].dataset.price;
      let freefrom = event.target.options[event.target.selectedIndex].dataset.freefrom;
      console.log(freefrom, price);
      $('#address_price').val(price);
      $("#freefrom").val(freefrom);
      console.log("#freefrom изменен");
    })

    $("#freefrom").on("change", function () {
      console.log('changed freefrom wtf', $('#freefrom').val());
    })

    $("#addressesSelect").change(() => {
      console.log('addressesSelect change');
      this.setCheckoutTotals();
    })

  }

  setCheckoutAddressField(addresses) {
    $("#addressesSelect").html(`
      ${addresses.map(a => {
      return `
          <option value="${a.uuid}">${a.address}</option>
        `;
    }
    )
      }
    `)
  }

  setCheckoutDeliveryTimeField(deliveryTimes) {
    $("#deliveryTimesSelect").html(`
      ${deliveryTimes.map((t, idx) => `
        <option value="${t}">${t}</option>
      `)}
    `)
  }

  setCheckoutRestaurantsField(restaurants) {
    $("#pickupInput").html(`
      <label class="field select">
        <small>${translate('where_get_from')}</small>
        <select name="restaurant" id="restaurant-for-pickup">
          ${restaurants.map(r => `
            <option value="${r.uuid}">${r.name}</option>
          `)}
        </select>
      </label>
    `);

    $("select#restaurant-for-pickup").on("change", () => {
      this.checkTimeForDeliveryOrPickup(restaurants);
    })
  }

  setCheckoutAnothermanFields() {
    $('#anothermanData').hide();
    $('input[type=checkbox][name=anotherman]').change(function () {
      if (this.checked) {
        $('#anothermanData').fadeIn();
      } else {
        $('#anothermanData').hide();
      }
    })
  }

  firstTimeBiggerThanSecondTime(firstHour, firstMinute, secondHour, secondMinute) {
    firstHour = Number(firstHour);
    firstMinute = Number(firstMinute);
    secondHour = Number(secondHour);
    secondMinute = Number(secondMinute);
    if (firstHour === secondHour && firstMinute === secondMinute) {
      return false;
    }
    if (firstHour > secondHour) {
      return true;
    }
    if (firstHour === secondHour && firstMinute > secondMinute) {
      return true;
    }
    if (firstHour < secondHour) {
      return false;
    }
    if (firstHour === secondHour && firstMinute < secondMinute) {
      return false;
    }
  }

  checkTimeForDelivery(deliveryFromTime, deliveryToTime) {
    const currentHour = +new Date().getHours();
    const currentMinute = +new Date().getMinutes();

    const deliveryFromTimeHour = deliveryFromTime.split(':')[0].trim();
    const deliveryFromTimeMinute = deliveryFromTime.split(':')[1].trim();
    const deliveryToTimeHour = deliveryToTime.split(':')[0].trim();
    const deliveryToTimeMinute = deliveryToTime.split(':')[1].trim();
    const deliveryFromTimeBiggerThanDeliveryToTime = this.firstTimeBiggerThanSecondTime(deliveryFromTimeHour, deliveryFromTimeMinute, deliveryToTimeHour, deliveryToTimeMinute);
    const currentTimeBiggerThanDeliveryFromTime = this.firstTimeBiggerThanSecondTime(currentHour, currentMinute, deliveryFromTimeHour, deliveryFromTimeMinute);
    const currentTimeBiggerThanDeliveryToTime = this.firstTimeBiggerThanSecondTime(currentHour, currentMinute, deliveryToTimeHour, deliveryToTimeMinute);
    const currentTimeBiggerMidnightTime = this.firstTimeBiggerThanSecondTime(currentHour, currentMinute, 23, 59);
    if (
      !deliveryFromTimeBiggerThanDeliveryToTime
    ) {
      return (
        currentTimeBiggerThanDeliveryFromTime &&
        !currentTimeBiggerThanDeliveryToTime
      );
    }
    else if (
      deliveryFromTimeBiggerThanDeliveryToTime
    ) {
      return (
        (currentTimeBiggerThanDeliveryFromTime && !currentTimeBiggerMidnightTime)
        || (currentTimeBiggerMidnightTime && !currentTimeBiggerThanDeliveryToTime)
      );
    }
  }

  checkTimeForPickup(deliveryFromTime, deliveryToTime) {
    const currentHour = +new Date().getHours();
    const currentMinute = +new Date().getMinutes();

    const deliveryFromTimeHour = deliveryFromTime.split(':')[0].trim();
    const deliveryFromTimeMinute = deliveryFromTime.split(':')[1].trim();
    const deliveryToTimeHour = deliveryToTime.split(':')[0].trim();
    const deliveryToTimeMinute = deliveryToTime.split(':')[1].trim();
    const deliveryFromTimeBiggerThanDeliveryToTime = this.firstTimeBiggerThanSecondTime(deliveryFromTimeHour, deliveryFromTimeMinute, deliveryToTimeHour, deliveryToTimeMinute);
    const currentTimeBiggerThanDeliveryFromTime = this.firstTimeBiggerThanSecondTime(currentHour, currentMinute, deliveryFromTimeHour, deliveryFromTimeMinute);
    const currentTimeBiggerThanDeliveryToTime = this.firstTimeBiggerThanSecondTime(currentHour, currentMinute, deliveryToTimeHour, deliveryToTimeMinute);
    const currentTimeBiggerMidnightTime = this.firstTimeBiggerThanSecondTime(currentHour, currentMinute, 23, 59);
    if (
      !deliveryFromTimeBiggerThanDeliveryToTime
    ) {
      return (
        currentTimeBiggerThanDeliveryFromTime &&
        !currentTimeBiggerThanDeliveryToTime
      );
    }
    else if (
      deliveryFromTimeBiggerThanDeliveryToTime
    ) {
      return (
        (currentTimeBiggerThanDeliveryFromTime && !currentTimeBiggerMidnightTime)
        || (currentTimeBiggerMidnightTime && !currentTimeBiggerThanDeliveryToTime)
      );
    }
  }

  setupButtonForDelivery() {
    const submitBtn = $('#orderCreateForm').find('#submitButton')[0];

    const city = JSON.parse(localStorage.getItem('currentCity'));
    const deliveryFromTime = city.deliverystart.trim();
    const deliveryToTime = city.deliveryend.trim();

    if (!this.checkTimeForDelivery(deliveryFromTime, deliveryToTime)) {
      const fromTime = deliveryFromTime.toString().substring(0, 5);
      const toTime = deliveryToTime.toString().substring(0, 5);
      submitBtn.setAttribute('disabled', 'disabled');
      submitBtn.innerHTML = (
        `${translate('delivery_is_not_open_yet')}. ${translate('working_time')}: ${fromTime} - ${toTime}`
      );
    } else {
      submitBtn.removeAttribute('disabled');
      submitBtn.innerHTML = (`${translate('confirm_order')}`);
    }
  }

  setupButtonForPickup(currentCityRestaurants) {
    const submitBtn = $('#orderCreateForm').find('#submitButton')[0];

    const selectedRestaurantId = $("select#restaurant-for-pickup").find(":selected").val();
    const selectedRestaurant = currentCityRestaurants.find(item => {
      return item.uuid === selectedRestaurantId
    });
    const deliveryFromTime = selectedRestaurant.deliveryhours.split('-')[0].trim();
    const deliveryToTime = selectedRestaurant.deliveryhours.split('-')[1].trim();

    const fromTime = deliveryFromTime.toString().substring(0, 5);
    const toTime = deliveryToTime.toString().substring(0, 5);
    console.log(toTime, fromTime);

    if (!this.checkTimeForPickup(deliveryFromTime, deliveryToTime)) {
      submitBtn.innerHTML = (
        `${translate('restaurant_is_not_open_yet')}. ${translate('working_time')}: ${fromTime} - ${toTime}`
      );
    } else {
      submitBtn.innerHTML = (`${translate('confirm_order')}`);
    }
  }

  checkTimeForDeliveryOrPickup(restaurants) {
    const deliveryModeValue = $('#radio-group input:checked').val();
    const city = JSON.parse(localStorage.getItem('currentCity'));
    const currentCityRestaurants = restaurants.filter(r => {
      if (city) {
        return r.city_uuid === city.uuid
      }
      return false
    });

    if (Number(deliveryModeValue) === 0) {
      this.setupButtonForDelivery();
    } else {
      this.setupButtonForPickup(currentCityRestaurants);
    }
  }

  setCheckoutDeliveryPickup(restaurants) {
    $('input[type=radio][name=pickup]')[0].checked = true;
    $('input[type=radio][name=pickup]')[1].checked = false;

    let current_city = JSON.parse(localStorage.getItem("currentCity"));

    this.checkTimeForDeliveryOrPickup(restaurants);

    $("#pickupInput").hide();
    $("#radio-group").change(() => {
      this.checkTimeForDeliveryOrPickup(restaurants);
      this.setCheckoutTotals();
    });

    if (!current_city.delivery) {
      $('#deliver_to_home_radio').hide();
      $('#take_myself_radio').css('width', '100%');
      $('#take_myself_radio span').css('background', '#fff');
      $("#checkout_selectPaymentMethod").hide();
      $("#pickupInput").fadeIn();
      $("#deliverInput").hide();
    }

    $('input[type=radio][name=pickup]').change(function () {
      if (Number(this.value)) {
        $("#pickupInput").fadeIn();
        $("#deliverInput").hide();
        let currAddressPrice = $('#address_price').val();
        let currFreefromPrice = $('#freefrom').val();
        $('#default_address_price').val(currAddressPrice);
        $('#default_freefrom_price').val(currFreefromPrice);
        $('#address_price').val(0);
        $('#freefrom').val(0);
        $('#delivery-amount').hide();
        $("#checkout_selectPaymentMethod").hide();
        $('input[type=radio][name=paymentmethod]')[0].checked = true;
        $('input[type=radio][name=paymentmethod]')[1].checked = false;
        $('#addressesSelectHidden').val('12345')
        $('#addressesSelect').hide();
        $("#prepareChange").hide();
      } else {
        $("#deliverInput").fadeIn();
        $("#pickupInput").hide();
        $('#delivery-amount').fadeIn();
        $('#addressesSelect').fadeIn();
        $('#addressesSelectHidden').val($('#addressesSelect').val())
        $("#checkout_selectPaymentMethod").fadeIn();
        let deliveryPrice = $('#default_address_price').val();
        let freefromPrice = $('#default_freefrom_price').val();
        $('#address_price').val(deliveryPrice);
        $('#freefrom').val(freefromPrice);
      }
    });
  }

  setCheckoutPaymentMethods(paymentMethods) {
    console.log(paymentMethods);
    const paymentMethodsContainer = $("#paymentMethods");

    const paymentMethodInputs = paymentMethods.map((p, idx) => `
     <label class="radio">
        <input
          
          type="radio"
          name="paymentmethod"
          value="${p.uuid}"
          ${idx === 0 && "checked"}
        />
        <span>
          ${(p.name === 'Наличными курьеру')
        ? translate('by_cash')
        : (p.name === 'Картой курьеру')
          ? translate('by_card')
          : translate('by_equiering')
      }
        </span>
      </label>
    `).join("");

    paymentMethodsContainer.html(`
      <div class="flex radiogroup">
          ${paymentMethodInputs}                  
      </div>
    `);

    $("#prepareChange").hide();
    $('input[type=radio][name=paymentmethod]').change(function () {
      $('input[type=radio][name=paymentmethod]')[0].checked || $('input[type=radio][name=paymentmethod]')[2].checked
        ? $("#prepareChange").hide()
        : $("#prepareChange").fadeIn();
    });

  }

  bonusMapPayPrice() {
    const userBonuses = Number($("#user_bonuses").val());
    const totalPrice = this.getTotalPrice();
    const percent = DataHelper.store.bonuses.paymentpercent || '0.00';
    const forCartMaxBonus = totalPrice * +(percent.toString().split('.')[0]) / 100;
    const result = Math.min(forCartMaxBonus, userBonuses);
    return result;
  }


  getTotalPrice() {
    const cart = JSON.parse(localStorage.getItem("cart"));
    return cart.reduce((acc, cur) => {
      const totalPriceForDish = Number(cur.price) * Number(cur.count);
      return acc + totalPriceForDish;
    }, 0);
  }

  setCheckoutBonusFields() {
    const bonuses = DataHelper.store.bonuses;

    const checkoutBonusFieldsContainer = $('#checkout_bonusFieldsContainer');

    const bonusBalance = Number(bonuses.balance) || 0;
    let amountOfBonusesCanSpend = bonusBalance;

    const totalPrice = this.getTotalPrice();

    if (bonusBalance >= totalPrice / 2) {
      amountOfBonusesCanSpend = totalPrice / 2;
    }

    checkoutBonusFieldsContainer.html(`
      <label class="field switch" style="width: 50%; display: none;">
        <h5>${translate('spend_bonuses')}</h5>
        <h6>${translate('able_to_spend')}: ${this.bonusMapPayPrice()}</h6>
        <input id="checkoutBonusCheckbox" type="checkbox" value="" />
        <span class="button"><span></span></span>
      </label>
    
      
      <div style="width: 50%; display: none;">
        <label class="field bonus">
          <small>${translate('how_much_bonuses_spend')}</small>
          <input id="checkoutBonusNumberInput" data-bonuses-balance="${amountOfBonusesCanSpend}" type="number" name="bonuses"  autocomplete="off" />
        </label>
        <div class="error__mesage" hidden>
          ${translate('cannot_spend_more_bonuses')}
        </div>
      </div>
     
    `);

    $("#checkoutBonusNumberInput").change(() => {
      this.setCheckoutTotals();
    })

    $("#checkoutBonusCheckbox").change((e) => {
      if (event.target.checked) {
        $("#checkoutBonusNumberInput").prop("disabled", false);
        $("#checkoutBonusNumberInput").parent().css("background-color", "transparent");
        $("#checkoutBonusNumberInput").val(this.bonusMapPayPrice());
      } else {
        $("#checkoutBonusNumberInput").prop("disabled", true);
        $("#checkoutBonusNumberInput").val('');
        $("#checkoutBonusNumberInput").parent().css("background-color", "#efefef")
      }
      this.setCheckoutTotals();
    });
  }


  countTotalPrice(pricesSum, bonusValue, deliveryPrice, freefrom) {
    let deliveryPriceWithFreeFrom = (Number(pricesSum) >= $("#freefrom").val()) ? 0 : (deliveryPrice);

    let deliveryAmount = `<li id="delivery-amount"><span>${translate('delivery_amount')}</span><span id="delivery_price">${deliveryPriceWithFreeFrom} ₸</span></li>`;
    if (deliveryPrice == 0) deliveryAmount = '';

    const totalPrice = pricesSum - bonusValue + deliveryPriceWithFreeFrom;

    $(".totals").html(`
      <ul>
        <li><span>${translate('cart')}</span><span>${pricesSum} ₸</span></li>
        <li style="display: none;"><span>${translate('bonuses')}</span><span class="bonuses_total">${bonusValue} ₸</span></li>
        ${deliveryAmount}
        <li><span>${translate('total')}</span><span class="total_price">${totalPrice} ₸</span></li>
      </ul>
    `)
  }

  setCheckoutTotals(deliveryPrice = null, freefromPrice = null) {

    if (!deliveryPrice) {
      deliveryPrice = $('#address_price').val()
    }

    if (!freefromPrice) {
      freefromPrice = $('#freefrom').val()
    }

    $('#delivery_price').val(deliveryPrice);
    $('#freefrom').val(freefromPrice)


    const cart = JSON.parse(localStorage.getItem("cart"));
    const pricesSum = cart.reduce((acc, cur) => {
      const totalPriceForDish = Number(cur.price) * Number(cur.count);
      return acc + totalPriceForDish;
    }, 0);

    const bonusValue = Number($("#checkoutBonusNumberInput").val()) || 0;
    const freefrom = JSON.parse(JSON.stringify(Number($("#freefrom").val())));
    let countTotalPrice = this.countTotalPrice;
    countTotalPrice(pricesSum, bonusValue, +deliveryPrice, freefrom)

    let mapPay = this.bonusMapPayPrice()
    $('#checkoutBonusNumberInput').on('keyup', function (e) {
      let value = +e.target.value

      if (value > mapPay) {
        value = mapPay
        $(this).val(value)
      }

      countTotalPrice(pricesSum, value, +deliveryPrice, freefrom)
    })


  }

  setCheckoutCartItems(cartItems, additionalDishes) {
    const render = (cartItems) => {
      this.setCheckoutBonusFields();
      this.setCheckoutTotals();
      $("#checkout_productsContainer").html(
        `${cartItems.map(cartItem => {
          return `
        <div class="product">
          <div class="flex">
            <a href="#product" title="" class="image open">
              <img src="${cartItem.image}" alt="" />
            </a>
            <div class="col flex column jc-sb">
              <div class="flex jc-sb">
                <div class="description">
                  <h5>
                    <a href="#product" title="" class="open"
                      >${cartItem.name}</a
                    >
                  </h5>
                  <h6>${cartItem.description ? cartItem.description : ""}</h6>
                </div>
                <button class="remove" data-cart-item="${cartItem.uuid}"></button>
              </div>
              <div class="flex jc-sb ai-end">
                <h5>${cartItem.saleprice ? cartItem.saleprice : cartItem.price} ₸</h5>
                <div class="quantity">
                  <button type="button" data-cart-item="${cartItem.uuid}" class="minus">-</button>
                  <input
                    class="cart-item-count"
                    type="number"
                    name="quantity"
                    data-cart-item="${cartItem.uuid}"
                    value="${Number(cartItem.quantity)}"
                    readonly
                  />
                  <button type="button" data-cart-item="${cartItem.uuid}" class="plus">+</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      `
        }).join("")}`
      );

      $("#checkout_productsContainer").append(
        `
          <div class="additional">
            <h3>${translate('dont_forget_to_add')}</h3>
            <div class="flex">
              ${additionalDishes.map(dish => `
                <div class="item">
                  <img src="${dish.image}" alt="" />
                  <h5>${dish.name}</h5>
                  <button data-add-dish-item="${dish.uuid}" class="btn add-dish-btn">${translate('add_for')} ${dish.price} ₸</button>
                </div>
              `).join("")}
            </div>
          </div>
        `
      )

      const totalQuantity = cartItems.reduce((acc, cur) => acc + Number(cur.quantity), 0);
      let dishText = "";
      if (totalQuantity === 1) {
        dishText = translate('one_dish');
      } else if (totalQuantity >= 2 && totalQuantity <= 4) {
        dishText = translate('two_to_four_dishes');
      } else {
        dishText = translate('many_dishes');
      }


      $("#dishesNumberContainer").html(
        `<h3>${translate('you_are_ordering')} ${totalQuantity} ${dishText}</h3>`
      )


      $("button.minus").click(function (e) {
        console.log('buttons minus in cartItem')
        const id = $(this).data("cartItem");
        const input = $(`input.cart-item-count[data-cart-item="${id}"]`);
        const inputVal = Number(input.val());
        const newInputVal = inputVal <= 1 ? 1 : inputVal - 1;
        input.val(newInputVal);

        const cart = JSON.parse(localStorage.getItem("cart"));
        const newCart = cart.map(c => (c.id === id) ? ({ ...c, count: newInputVal }) : c);
        localStorage.setItem("cart", JSON.stringify(newCart));
        const newCartItems = cartItems.map(c => (c.uuid === id) ? ({ ...c, quantity: newInputVal }) : c);

        render(newCartItems);
      });

      $("button.plus").click(function (e) {
        console.log('buttons plus in cartItem')
        const id = $(this).data("cartItem");
        const input = $(`input.cart-item-count[data-cart-item="${id}"]`);
        const inputVal = Number(input.val());
        const newInputVal = inputVal + 1;
        const cart = JSON.parse(localStorage.getItem("cart"));
        const newCart = cart.map(c => (c.id === id) ? ({ ...c, count: newInputVal }) : c);
        localStorage.setItem("cart", JSON.stringify(newCart));
        const newCartItems = cartItems.map(c => (c.uuid === id) ? ({ ...c, quantity: newInputVal }) : c);
        render(newCartItems);
      });

      $("button.remove").click(function (e) {
        const id = $(this).data("cartItem");
        const cart = JSON.parse(localStorage.getItem("cart"));
        const newCart = cart.filter(c => c.id !== id);
        localStorage.setItem("cart", JSON.stringify(newCart));
        const newCartItems = cartItems.filter(c => c.uuid !== id);
        render(newCartItems)
      })

      $("button.add-dish-btn").click(function (e) {
        const id = $(this).data("addDishItem");
        const cart = JSON.parse(localStorage.getItem("cart"));
        if (cartItems.find(c => c.id === id)) {
          return;
        }
        const dish = additionalDishes.find(i => i.uuid === id);
        const newCart = cart.concat({ ...dish, id: dish.uuid, count: 1, price: dish.price });
        console.log({ newCart });
        localStorage.setItem("cart", JSON.stringify(newCart));
        const newCartItems = cartItems.concat({ ...dish, id: dish.uuid, quantity: 1, price: dish.price });

        render(newCartItems);
      })


    }

    render(cartItems);
  }

  setReservationRestaurantsField(restaurants) {
    $("#reservation-restaurant").html(`
      ${restaurants.map(r => `
        <option value="${r.uuid}">${r.name}</option>
      `)}
    `)
  }

  showResPassModal() {
    $(".popup").fadeOut();
    $("#password").fadeIn();
    $("body").css({ overflow: "hidden" });
  }

  hideModalWindows(isNeedToCleanInputs) {
    let popup = $(".popup").not(".popup selectCityModal");
    popup.fadeOut();

    if ($(".view").is(":visible")) {
      $("body").css({ overflow: "hidden" });
    } else {
      $("body").css({ overflow: "visible" });
    }

    isNeedToCleanInputs && this.clearModalInputs();
  }

  clearModalInputs() {
    const { inputs } = DataHelper.store;

    console.log('inputs', inputs)

    Object.values(inputs).forEach((el) => {
      if (el) {
        el.value = null;
      }
    });
  }

  setTopCityInfo(name, phone) {
    document.querySelector(".city-name").innerText = name;
    document.querySelector(".tel").innerText = phone;
    document.querySelector(".tel").href = "tel:" + phone;
  }

  setLangs() {
    const block = document.querySelector("#langs");
    const currentLang = localStorage.getItem("currentLang") || 'RU';

    block.innerHTML = `
      <button class="dropdown-toggle lang">${currentLang || "ru"}</button>
      <ul class="dropdown-menu" style="width: 100px;">
        <li><a data-lang="RU" class="${currentLang === 'RU' ? "active" : ""}">RU</a></li>
        <li><a data-lang="KZ" class="${currentLang === 'KZ' ? "active" : ""}">KZ</a></li>
        <li><a data-lang="EN" class="${currentLang === 'EN' ? "active" : ""}">EN</a></li>
      </ul>
    `;
  }

  setTopCities(cities, onCityChange) {
    const modal = document.querySelector("#cities-form");
    if (modal) {
      modal.innerHTML = `
                ${cities
          .map(
            (city) => `
                            <label class="field">
                                <input type="radio" name="city" value="${city.uuid}"/>
                                <span>${city.name
              }</span>
                            </label>
                        `
          )
          .join("")}
                        <div class="flex wrap jc-end">
                            <button class="button" id="save-city">Сохранить</button>
                        </div>
                    `;
    }

    const block = document.querySelector("#cities");
    const current = JSON.parse(localStorage.getItem("currentCity")) || cities[0];
    block.innerHTML = `
            <button class="dropdown-toggle city-name">
            </button>
            <ul class="dropdown-menu">
                ${cities
        .map(
          (city) => `
                              <li><a data-uuid="${city.uuid}" class="${city.uuid === current.uuid ? "active" : ""
            }">${city.name
            }</a></li>  
                          `
        )
        .join("")}
            </ul>
        `;
    this.setTopCityInfo(current.name || city[0].name, current.phone);
  }

  setBanners(stocks) {
    const block = document.querySelector(".slides");
    stocks.forEach((stock) => {
      block.innerHTML =
        block.innerHTML +
        `
                <div class="slide" data-id="${stock.uuid}">
                    <div class="image">
                        <img src="${stock.image}" alt="" width="1400" style="min-height: 420px" />
                    </div>
                    <a href="${stock.link}" target="_blank" title="" class="full open"></a>
                </div>
            `;
    });

    $(".slides").slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerMode: false,
            variableWidth: false,
          }
        }
      ]
    });

    if ($(window).width() > 736) {
      $(".scroll-x").mCustomScrollbar({
        axis: "x",
        callbacks: {
          onScrollStart: function () {
            var top = $("#gallery").offset().top;
            $("body, html").animate({ scrollTop: top + 200 }, "fast");
          },
        }
      });
    } else {
      $(".scroll-x").slick({
        dots: false,
        arrows: false,
        centerMode: true,
        infinite: false
      });
    }

    $("body").css("overflow", "visible");

  }

  setInputError(el) {
    $(el.parentElement).addClass("error");
    $(el.parentElement).next().show();
  }

  removeAllInputErrors() {
    $(".error__mesage").each(function () {
      $(this).hide();
    });

    $(".error").each(function () {
      $(this).removeClass("error");
    });
  }

  setPromotion(promo) {
    const block = document.querySelector("#main");
    block.innerHTML =
      block.innerHTML +
      `
            <section id="banner">
                <div class="container" style="text-align: center">
                    <img src="${promo.fullimage}" alt="">
                </div>
            </section>
            <section id="promotion">
                <div class="container">
                    <div class="title">
                        <h1>${promo.name}</h1>
                    </div>
                    <div class="content">
                        <h5 class="time">${translate('valid_until')}: ${promo.date}</h5>
                        <hr/>
                        <h4>${promo.description}</h4>
                       
                    </div>
                </div>
            </section>
        `;
    // <p>${promo.fulldescription}</p>
  }

  setPromotions(promos) {
    const block = document.querySelector("#promos");
    promos.forEach((promo) => {
      block.innerHTML =
        block.innerHTML +
        `
                <div class="col">
                <div class="promotion">
                <div class="flex">
                <a href="?id=${promo.uuid}" title="" class="image">
                <img src="${promo.image}" alt="" />
                </a>
                <div class="content flex column jc-sb">
                <div class="description">
                <h4><a href="?id=${promo.uuid}" title="">${promo.name}</a></h4>
                <p>${promo.description}</p>
                </div>
                <a href="?id=${promo.uuid}" title="" class="btn">${translate('more_detailed')}</a>
                </div>
                </div>
                </div>
                </div>
            `;
    });
  }

  setNews(promos) {
    const block = document.querySelector("#news");
    promos.forEach((promo) => {
      block.innerHTML =
        block.innerHTML +
        `
                <div class="col">
                <div class="promotion">
                <div class="flex">
                <div class="flex column w-100">
                <div class="description">
                <h4><a href="" title="">${promo.name}</a></h4>
                <p>${promo.description}</p>
                </div>
                <a href="?id=${promo.uuid}" title="" class="btn">${translate('more_detailed')}</a>
                </div>
                </div>
                </div>
                </div>
            `;
    });
  }

  async setMenusContent(menuItems) {
    // console.log(menuItems)
    const menu = menuItems.reduce((acc, curr) => {
      const folderId = curr.folder_uuid;
      const folderName = curr.folder_name;
      const folderTranslit = curr.folder_translit;

      if (!acc[folderId]) {
        acc[folderId] = { name: folderName, translit: folderTranslit, items: [curr] };
      } else {
        acc[folderId].items.push(curr);
      }

      return acc;
    }, {});
    // console.log(123, menu);
    this.setCategories(menu);
    await this.setMenu(menu);
    return menu;
  }

  setWishlistContent(menuItems) {
    const menu = menuItems.reduce((acc, curr) => {
      const folderId = curr.folder_uuid;
      const folderName = curr.folder_name;
      const folderTranslit = curr.folder_translit;

      if (!acc[folderId]) {
        acc[folderId] = { name: folderName, translit: folderTranslit, items: [curr] };
      } else {
        acc[folderId].items.push(curr);
      }

      return acc;
    }, {});
    this.setCategoriesProfile(menu);
    return menu;
  }

  async setMenu(menu) {
    const block = document.querySelector("#main-menu");
    const favouriteList = JSON.parse(localStorage.getItem("favourite")) || [];

    // console.log('favouriteList', favouriteList);


    const allDishes = Object.values(menu).map(({ items }) => items).reduce((acc, cur) => {
      return acc.concat(cur);
    }, []);
    // console.log('allDishes', allDishes)
    const renderTimeCartState = JSON.parse(localStorage.getItem("cart")) || [];

    const genItems = (items, favouriteList = []) => {
      let itemsGen = (items
        .map((item) => {

          let wishlist = favouriteList.filter(
            function (fav) {
              return fav.uuid === item.uuid;
            }
          );
          let isInWishlist = (wishlist.length > 0) ? "wishlist-active" : "";
          // console.log(isInWishlist);
          return `<div class="col">
                    <div class="product" data-id="${item.uuid}" itemscope="" itemtype="http://schema.org/Product">
                        <div class="image">
<!--                            <a href="" title="" class="open">-->
                            <img src="${item.image}" alt="" itemprop="image"/>
<!--                            </a>-->
                            <div class="properties">
                                ${item.withchicken ? `<span class="property-1"></span>` : ""}
                                ${item.withsalad ? `<span class="property-2"></span>` : ""}
                                ${item.spicy ? `<span class="property-3"></span>` : ""}
                            </div>
                            ${item.sale ? `<span class="info">SALE</span>` : ""}
                            
                        </div>
                        <div class="flex jc-sb ai-c">
<!--                            <a href="#product-modal" title="" class="product-name open"></a>-->
                            <span class="product-name" itemprop="name">${item.name} ${item.weight ? `<span style="color: #6f747c;">• ${item.weight}</span>` : ''}</span>
                            <button data-uuid="${item.uuid}" class="wishlist ${isInWishlist}" 
                              data-id="${item.uuid}"/>
                        </div>
                        <div class="description" itemprop="description">${item.description}</div>
                        <div class="flex jc-sb ai-c">
                            <div class="price" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer">
                                ${item.sale ? `<span class="old-price">${item.price} ₸</span>` : ""}
                                
                                <span class="new-price" itemprop="price">${item.sale ? item.saleprice : item.price} ₸</span>
                                <span itemprop="priceCurrency" style="display: none;">KZT</span>
                                <link style="display: none;" itemprop="availability" href="http://schema.org/InStock">
                                <div style="display: none;" itemprop="reviewRating" itemscope="" itemtype="http://schema.org/Rating">
                                  <meta itemprop="worstRating" content="0">
                                  <span itemprop="ratingValue">4,8</span>
                                  <span itemprop="bestRating">5</span>
                                </div>
                            </div>
                            <div class="buttons" data-id="${item.uuid}">
                            </div>
                        </div>
                    </div>
                </div>`;
        })
        .join(""))

      // console.log(itemsGen)
      return itemsGen;
    };

    let firstCategory = null;

    const lang = localStorage.getItem('currentLang') || 'ru';
    await api.getWishes(lang)
      .then(data => {
        const dish = allDishes.find(d => d.uuid === dishId);
        console.log('data', data);
        Object.keys(menu).forEach((id, index) => {
          if (index === 0) firstCategory = menu[id].translit;
          block.innerHTML =
            block.innerHTML +
            `
            <section class="category category-anchor" id="${menu[id].translit}">
                <div>
                    <h2>${menu[id].name}</h2>
                    <div class="products">
                        <div class="row flex wrap">
                            ${genItems(menu[id].items, data)}
                        </div>
                    </div>
                </div>
            </section>
          `;
        });

        document.querySelectorAll('.wishlist').forEach(button => {
          button.addEventListener('click', function () {
            let button = $(this)
            let dishId = button.attr('data-uuid')
            console.log('uuid', dishId);
            if (button.hasClass('wishlist-active')) {
              api.deleteWish(dishId)
                .then(data => {
                  button.removeClass('wishlist-active')
                })
            } else {
              api.addWish(dishId)
                .then(data => {
                  button.addClass('wishlist-active')
                })
            }
          })
        })


        document.querySelectorAll(".buttons").forEach((el) => {
          const dishId = el.getAttribute("data-id");
          const dish = allDishes.find(d => d.uuid === dishId);
          const dishInCart = renderTimeCartState.find(c => c.id === dishId);

          console.log('dish', alldDishes, dish, dishId, el, el.getAttribute("data-id"));


          if (!dish) {
            return
          }

          // console.log('el', el);


          if (dishInCart) {
            el.innerHTML = `
              <div class="quantity">
                  <button class="minus" data-id="${dishId}">-</button>
                  <input type="text" name="quantity" value="${dishInCart.count}" readOnly/>
                  <button class="plus" data-id="${dishId}">+</button>
              </div>
            `
          } else {
            el.innerHTML = `
              <button 
                class="buy" 
                data-price="${dish.sale ? dish.saleprice : dish.price}" 
                data-id="${dish.uuid}"
              >
                  ${translate('add')}
              </button>
            `
          }
        });

        // Adding favourite functionality
        document.querySelectorAll(".wishlist").forEach((item) => {
          item.addEventListener("click", (event) => {
            const favouriteList =
              JSON.parse(localStorage.getItem("favourite")) || [];

            const productId = event.currentTarget.getAttribute("data-id");
            let updatedList = [...favouriteList];

            if (favouriteList.includes(productId)) {
              const newList = updatedList.filter((item) => item !== productId);
              localStorage.setItem("favourite", JSON.stringify(newList));
              event.currentTarget.className = "wishlist";
            } else {
              updatedList.push(productId);
              localStorage.setItem("favourite", JSON.stringify(updatedList));
              event.currentTarget.className = "wishlist wishlist-active";
            }
          });
        });




        // Added acnhor link for first element - Доставка домой
        const deliveryBlock = $(document).find(`[data-id='home-delivery']`);
        const deliveryLink = deliveryBlock.find("a");
        deliveryLink.attr("href", `#${firstCategory}`);

        const minusButtonListener = (event) => {
          const dishId = event.target.getAttribute("data-id");
          const cart = JSON.parse(localStorage.getItem("cart")) || [];
          const dishInCart = cart.find(c => c.id === dishId);
          const dish = allDishes.find(d => d.uuid === dishId);
          const decrementedValue = dishInCart.count - 1;
          const filteredCart = cart.filter(c => c.id !== dishId);

          const newCart = decrementedValue > 0 ? filteredCart.concat([{ ...dishInCart, count: decrementedValue }]) : filteredCart;

          const parentElement = event.target.parentElement;
          const buttonsClassElement = parentElement.parentElement;


          if (decrementedValue > 0) {
            parentElement.children[1].value = decrementedValue;
          } else {
            buttonsClassElement.innerHTML = `
                  <button 
                  class="buy" 
                  data-price="${dish.sale ? dish.saleprice : dish.price}" 
                  data-id="${dish.uuid}"
                >
                    ${translate('add')}
                </button>
              `;

            buttonsClassElement.querySelector('.buy').addEventListener("click", addProductButtonListener);
          }

          localStorage.setItem("cart", JSON.stringify(newCart));
          this.setSummaryProductsButton(newCart);
        }

        const plusButtonListener = (event) => {
          const dishId = event.target.getAttribute("data-id");
          const cart = JSON.parse(localStorage.getItem("cart")) || [];
          const dishInCart = cart.find(c => c.id === dishId);
          const incrementedValue = dishInCart.count + 1;
          const newCart = cart.filter(c => c.id !== dishId).concat([{ ...dishInCart, count: incrementedValue }]);
          localStorage.setItem("cart", JSON.stringify(newCart));
          const parentElement = event.target.parentElement;
          parentElement.children[1].value = incrementedValue;

          this.setSummaryProductsButton(newCart);
        }

        const addProductButtonListener = (event) => {

          // console.log('BUY button clicked');



          const cartList = JSON.parse(localStorage.getItem("cart")) || [];

          const productPrice = event.currentTarget.getAttribute("data-price");
          const productId = event.currentTarget.getAttribute("data-id");
          let updatedList = [...cartList];

          const hasProduct = updatedList.find((item) => item.id === productId);

          if (hasProduct) {
            const newList = updatedList.map((item) => {
              if (item.id === productId) {
                item.count++;
              }

              return item;
            });
            localStorage.setItem("cart", JSON.stringify(newList));
          } else {
            const payload = {
              id: productId,
              price: productPrice,
              count: 1,
            };

            updatedList.push(payload);

            localStorage.setItem("cart", JSON.stringify(updatedList));
          }

          const parentElement = event.target.parentElement;

          parentElement.innerHTML = `
          <div class="quantity">
              <button class="minus" data-id="${productId}">-</button>
              <input type="text" name="quantity" value="1" readOnly/>
              <button class="plus" data-id="${productId}">+</button>
          </div>
        `;

          parentElement.querySelector(".minus").addEventListener("click", minusButtonListener);
          parentElement.querySelector(".plus").addEventListener("click", plusButtonListener);

          this.setSummaryProductsButton(updatedList);
        }


        // Adding buy functionality
        document.querySelectorAll(".buy").forEach((item) => {
          item.addEventListener("click", addProductButtonListener);
        });
        //

        document.querySelectorAll(".plus").forEach((el) => {
          el.addEventListener("click", plusButtonListener)
        })

        document.querySelectorAll(".minus").forEach((el) => {
          el.addEventListener("click", minusButtonListener)
        })
      })
      .catch(error => {
        Object.keys(menu).forEach((id, index) => {
          if (index === 0) firstCategory = menu[id].translit;
          block.innerHTML =
            block.innerHTML +
            `
                      <section class="category category-anchor" id="${menu[id].translit}">
                          <div>
                            <h2>${menu[id].name}</h2>
                              <div class="products">
                                  <div class="row flex wrap">
                                      ${genItems(menu[id].items)}
                                  </div>
                              </div>
                          </div>
                      </section>
                    `;
        });
      }
      ).finally(() => {
        categoriesMenuSelectListener();
      })


    document.querySelectorAll('.wishlist').forEach(button => {
      button.addEventListener('click', function () {
        let button = $(this)
        let dishId = button.attr('data-uuid')
        console.log('uuid', dishId);
        if (button.hasClass('wishlist-active')) {
          api.deleteWish(dishId)
            .then(data => {
              button.removeClass('wishlist-active')
            })
        } else {
          api.addWish(dishId)
            .then(data => {
              button.addClass('wishlist-active')
            })
        }
      })
    })
    // console.log('allButtons', document.querySelectorAll(".product"))
    document.querySelectorAll(".buttons").forEach((el) => {
      const dishId = el.getAttribute("data-id");
      const dish = allDishes.find(d => d.uuid === dishId);
      const dishInCart = renderTimeCartState.find(c => c.id === dishId);

      // console.log('dish', allDishes, dish, dishId, el, el.getAttribute("data-id"));


      if (!dish) {
        return
      }

      // console.log('el', el);


      if (dishInCart) {
        el.innerHTML = `
              <div class="quantity">
                  <button class="minus" data-id="${dishId}">-</button>
                  <input type="text" name="quantity" value="${dishInCart.count}" readOnly/>
                  <button class="plus" data-id="${dishId}">+</button>
              </div>
            `
      } else {
        el.innerHTML = `
              <button 
                class="buy" 
                data-price="${dish.sale ? dish.saleprice : dish.price}" 
                data-id="${dish.uuid}"
              >
                  ${translate('add')}
              </button>
            `
      }
    });

    // Adding favourite functionality
    document.querySelectorAll(".wishlist").forEach((item) => {
      item.addEventListener("click", (event) => {
        const favouriteList =
          JSON.parse(localStorage.getItem("favourite")) || [];

        const productId = event.currentTarget.getAttribute("data-id");
        console.log('productId', productId)
        let updatedList = [...favouriteList];

        if (favouriteList.includes(productId)) {
          const newList = updatedList.filter((item) => item !== productId);
          localStorage.setItem("favourite", JSON.stringify(newList));
          event.currentTarget.className = "wishlist";
        } else {
          updatedList.push(productId);
          localStorage.setItem("favourite", JSON.stringify(updatedList));
          event.currentTarget.className = "wishlist wishlist-active";
        }
      });
    });




    // Added acnhor link for first element - Доставка домой
    const deliveryBlock = $(document).find(`[data-id='home-delivery']`);
    const deliveryLink = deliveryBlock.find("a");
    deliveryLink.attr("href", `#${firstCategory}`);

    const minusButtonListener = (event) => {
      const dishId = event.target.getAttribute("data-id");
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const dishInCart = cart.find(c => c.id === dishId);
      const dish = allDishes.find(d => d.uuid === dishId);
      const decrementedValue = dishInCart.count - 1;
      const filteredCart = cart.filter(c => c.id !== dishId);

      const newCart = decrementedValue > 0 ? filteredCart.concat([{ ...dishInCart, count: decrementedValue }]) : filteredCart;

      const parentElement = event.target.parentElement;
      const buttonsClassElement = parentElement.parentElement;


      if (decrementedValue > 0) {
        parentElement.children[1].value = decrementedValue;
      } else {
        buttonsClassElement.innerHTML = `
             <button 
              class="buy" 
              data-price="${dish.sale ? dish.saleprice : dish.price}" 
              data-id="${dish.uuid}"
            >
                ${translate('add')}
            </button>
          `;

        buttonsClassElement.querySelector('.buy').addEventListener("click", addProductButtonListener);
      }

      localStorage.setItem("cart", JSON.stringify(newCart));
      this.setSummaryProductsButton(newCart);
    }

    const plusButtonListener = (event) => {
      const dishId = event.target.getAttribute("data-id");
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const dishInCart = cart.find(c => c.id === dishId);
      const incrementedValue = dishInCart.count + 1;
      const newCart = cart.filter(c => c.id !== dishId).concat([{ ...dishInCart, count: incrementedValue }]);
      localStorage.setItem("cart", JSON.stringify(newCart));
      const parentElement = event.target.parentElement;
      parentElement.children[1].value = incrementedValue;

      this.setSummaryProductsButton(newCart);
    }

    const addProductButtonListener = (event) => {

      // console.log('BUY button clicked');



      const cartList = JSON.parse(localStorage.getItem("cart")) || [];

      const productPrice = event.currentTarget.getAttribute("data-price");
      const productId = event.currentTarget.getAttribute("data-id");
      let updatedList = [...cartList];

      const hasProduct = updatedList.find((item) => item.id === productId);

      if (hasProduct) {
        const newList = updatedList.map((item) => {
          if (item.id === productId) {
            item.count++;
          }

          return item;
        });
        localStorage.setItem("cart", JSON.stringify(newList));
      } else {
        const payload = {
          id: productId,
          price: productPrice,
          count: 1,
        };

        updatedList.push(payload);

        localStorage.setItem("cart", JSON.stringify(updatedList));
      }

      const parentElement = event.target.parentElement;

      parentElement.innerHTML = `
          <div class="quantity">
              <button class="minus" data-id="${productId}">-</button>
              <input type="text" name="quantity" value="1" readOnly/>
              <button class="plus" data-id="${productId}">+</button>
          </div>
        `;

      parentElement.querySelector(".minus").addEventListener("click", minusButtonListener);
      parentElement.querySelector(".plus").addEventListener("click", plusButtonListener);

      this.setSummaryProductsButton(updatedList);
    }


    // Adding buy functionality
    document.querySelectorAll(".buy").forEach((item) => {
      item.addEventListener("click", addProductButtonListener);
    });
    //

    document.querySelectorAll(".plus").forEach((el) => {
      el.addEventListener("click", plusButtonListener)
    })

    document.querySelectorAll(".minus").forEach((el) => {
      el.addEventListener("click", minusButtonListener)
    })

    // $(window).on('load', categoriesMenuSelectListener());
  }


  setMainProductCardButtons(dish, allDishes) {
    console.log('dish', dish)
    let dishId = dish.uuid
    let productCard = document.querySelector(`.product[data-id=${dishId}]`)
    let buttonsContainer = productCard.querySelector('.buttons')
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    const dishInCart = cart.find(c => c.id === dishId);
    console.log('dishInCart', dishInCart)
    if (dishInCart) {
      buttonsContainer.innerHTML = `
          <div class="quantity">
              <button class="minus" data-id="${dishId}">-</button>
              <input type="text" name="quantity" value="${dishInCart.count}" readOnly/>
              <button class="plus" data-id="${dishId}">+</button>
          </div>
        `
    } else {
      buttonsContainer.innerHTML = `
          <button 
            class="buy" 
            data-price="${dish.sale ? dish.saleprice : dish.price}" 
            data-id="${dish.uuid}"
          >
              ${translate('add')}
          </button>
        `
    }




  }


  setSummaryProductsButton(updatedList) {
    const summaryProducts = updatedList.reduce(
      (sum, current) => {
        sum.total += Number(current.count);
        sum.price += current.count * Number(current.price);
        return sum;
      },
      { total: 0, price: 0 }
    );

    const productsSummaryBlock =
      document.getElementById("products-summary");
    productsSummaryBlock.className = "cart cart-filled";
    productsSummaryBlock.innerHTML = `${summaryProducts.total} ${translate('dish_for')} ${summaryProducts.price}₸`;
    if (summaryProducts.total <= 0) {
      productsSummaryBlock.className = "cart 0";
    }
  }

  setRestaurantsContent(restaurants) {
    const cityList = [];

    restaurants.forEach((rest) => {
      const city = { name: rest.city_name, id: rest.city_uuid };
      console.log('rest', rest)
      if (!cityList.find(e => e.name === city.name)) {
        console.log(!!cityList.find(e => e.name === city.name), city, 'exists');
        cityList.push(city);
      }
    });

    const city = JSON.parse(localStorage.getItem("currentCity")).name;
    this.setRestaurantsCityFilters(cityList);
    this.setRestaurants(restaurants, city);
  }

  setRestaurantsCityFilters(cityList) {
    const citiesFilter = document.querySelector("#city_filter");
    let current_city = JSON.parse(localStorage.getItem("currentCity")).name;
    cityList.forEach((city) => {
      citiesFilter.innerHTML =
        citiesFilter.innerHTML +

        `
          <${city.name === current_city ? "h1" : "span"} class="city ${city.name === current_city && "city__active"}" data-value="${city.name}" data-uuid="${city.id}">
              ${translate(city.name)
        }
          <${city.name === current_city ? "h1" : "span"}/>
        `;
    });
  }

  setCategories(menu) {
    // console.log(menu);
    const categories = Object.values(menu).map((item) => ({ name: item.name, translit: item.translit }));
    const block = document.querySelector("#categories");
    // console.log(categories);

    const categoryWrapper = document.createElement("div");

    categories.map((category) => {
      const categoryLink = document.createElement("a");
      categoryLink.href = `#${category.translit}`;
      categoryLink.classList = "category-anchor";
      categoryLink.setAttribute("data-anchor-link", category.translit);
      // console.log(category)
      let text = category.name;
      if (text.length >= 33) {
        text = text.slice(0, -3);
        text = text + '...';
      }
      const link = document.createTextNode(text);
      categoryLink.appendChild(link);

      categoryWrapper.append(categoryLink);
    });


    const outputWrapper = `
      <div class="container">
        <div class="flex jc-sb ai-l d-column">
          <h2>${translate('menu')}</h2>
          <div class="filter d-column categories-column">
            ${categoryWrapper.innerHTML}
          </div>
        </div>
      </div>
    `;


    block.innerHTML = block.innerHTML + outputWrapper;


    document.querySelectorAll(".category-anchor").forEach((category) => {
      category.addEventListener("click", (event) => {
        event.target.classList = "category-anchor active";
        document.querySelectorAll(".category-anchor").forEach(el => {
          if (event.target.innerHTML !== el.innerHTML) {
            el.classList = "category-anchor"
          }
        });
      })
    })
  }

  setCategoriesProfile(menu) {


    document.querySelectorAll(".category-anchor").forEach((category) => {
      category.addEventListener("click", (event) => {
        event.target.classList = "category-anchor active";
        document.querySelectorAll(".category-anchor").forEach(el => {
          if (event.target.innerHTML !== el.innerHTML) {
            el.classList = "category-anchor"
          }
        });
      })
    })

  }



  setRestaurants(restList, city) {
    console.log(city);
    const block = document.querySelector("#restList");
    const filteredRestList = restList.filter((item) => item.city_name === city);

    console.log(filteredRestList, 'filteredRestList')

    filteredRestList.forEach((rest) => {
      block.innerHTML =
        block.innerHTML +
        `
                 <div class="item" itemscope itemtype="http://schema.org/Restaurant">
                <div class="flex wrap">
                <div class="image">
                <img src="${rest.image}" alt="" itemprop="image"/>
                </div>
                <div class="content">
                <div class="flex wrap jc-sb ai-start">
                <div class="col">
                <h3 itemprop="name">${rest.name}</h3>
                <span style="display: none;" itemprop="acceptsReservations">Да</span>
                <a style="display: none;" itemprop="menu" href="https://turandot.kz/novinki-menyu"> Меню</a>
                <span style="display: none;" itemprop="servesCuisine">Китайская</span>
                <span style="display: none;" itemprop="priceRange">100</span>
                <div style="display: none;" itemprop="reviewRating" itemscope="" itemtype="http://schema.org/Rating"> 
                  <meta style="display: none;" itemprop="worstRating" content="0"> 
                  <span itemprop="ratingValue" style="display: none;">${rest.rating}</span> 
                  <span itemprop="bestRating" style="display: none;">5</span>
                </div>
                <div class="rating">
                <div class="restaurant-rating" data-rating="${rest.rating}"></div>
                </div>
                </div>
                <!--<a href="" title="" class="tour" style="display: none">3D тур</a>-->
                </div>
                <div class="flex wrap jc-sb ai-c">
                <div class="col">
                <small>${translate('delivery')}</small>
                <h5>${rest.deliveryhours}</h5>
                </div>
                <div class="col">
                <small>${translate('restaurant')}</small>
                <h5>${rest.workhours}</h5>
                </div>
                <div class="col">
                <a href="${rest.instagram}" target="_blank" title="" class="instagram">${translate('we_instagram')}</a>
                </div>
                <div class="col">
                <a href="#viewMap" title="" class="map" onclick="initMap(undefined, {lon: ${rest.longitude}, lat: ${rest.latitude}}, 14)">${translate('on_map')}</a>
                </div>
                </div>
                <div class="flex wrap jc-sb ai-c">
                <div class="col">
                <small>${translate('complaints_and_advices')}</small>
                <h5>${rest.email}</h5>
                </div>
                <div class="col">
                <a href="mailto:${rest.email}" title="" class="btn">${translate('write')}</a>
                </div>
                <div class="col">
                <small itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
                  ${translate('restaurant')}
                  <span style="display: none;" itemprop="streetAddress">${rest.address}</span>
                  <span style="display: none;" itemprop="addressLocality">${rest.city_name}</span> 
                  <span style="display: none;" itemprop="addressRegion">${rest.region}</span> 
                  <span style="display: none;" itemprop="postalCode">${rest.postal_code}</span>
                </small>
                <h5 itemprop="telephone">${rest.phone}</h5>
                </div>
                <div class="col">
                <a href="tel:${rest.phone}" title="" class="btn">${translate('make_a_call')}</a>
                </div>
                </div>
                <a onclick="setTimeout(()=>{$($('#reservation-modal')[0]).fadeIn()}, 10)" title="" data-restaurant="${rest.uuid}" title="" class="button open">${translate('book_table')}</a>
                </div>
                </div>
                </div>
             `;
    });

    $("#reservation__button").on("click", (e) => {
      e.preventDefault();
      $(".popup").fadeOut();
      const restaurantId = e.target.getAttribute("data-restaurant");
      const restaurant = filteredRestList.find(r => r.uuid === restaurantId)
      this.setReservationRestaurantsField([restaurant])
      $("#reservation-modal").fadeIn();
      $("body").css({ overflow: "hidden" });
    });



    $(".restaurant-rating").starRating({
      totalStars: 5,
      starSize: 25,
      readOnly: true,
      useFullStars: true,
      useGradient: false,
      starShape: "rounded",
      emptyColor: "#DEE3EB",
      activeColor: "#FFE600",
      strokeColor: "transparent",
    });

  }
}
