import { api } from "../../plugins/axios";
import { getUserToken, setUserToken } from "./Token";
import { formatPhoneNumber } from "../utils";
import { apiConfig } from "../../config";

export class Api {
  getUserProfile() {
    const payload = {
      type: "users",
      action: "getUserData",
      session: getUserToken(),
    };

    return api.post(null, payload).then(({ data }) => {
      const { name, phone, birthday, gender } = data;
      DataHelper.store.user = {
        name,
        phone,
        birthday,
        gender,
      };

      return data;
    });
  }

  setUserProfileInfo(user) {
    const payload = {
      type: "users",
      action: "updateUserData",
      name: user.name,
      gender: user.gender,
      birthday: user.birthday,
      phone: user.phone,
      session: getUserToken(),
      city: user.city
    };

    return api.post(null, payload).then(({ data }) => data);
  }

  changeUserPassword() { }

  restoreUserPassword(phone, pass, sms, step) {
    if (step === 1) {
      return api
        .post(null, {
          type: "users",
          action: "passwordRecovery1",
          phone: formatPhoneNumber(phone),
        })
        .then(({ data }) => {
          setUserToken(data.session);

          return data;
        });
    } else {
      return api
        .post(null, {
          type: "users",
          action: "passwordRecovery2",
          firebaseid: apiConfig.firebaseId,
          session: getUserToken(),
          password: pass,
          smscode: sms,
        })
        .then(({ data }) => {
          setUserToken(data.session);
          return data;
        });
    }
  }

  getUsersBonusBalance() {
    return api
      .post(null, {
        type: "bonuses",
        action: "getBalance",
        session: getUserToken(),
      })
      .then(({ data }) => {

        DataHelper.store.bonuses = data;
        return data;
      });
  }

  getUsersBonusLevel() {
    return api
      .post(null, {
        type: "bonuses",
        action: "getLevel",
        session: getUserToken(),
      })
      .then(({ data }) => {
        return data;
      });
  }

  getUsersBonusHistory(limit, page) {
    return api
      .post(null, {
        type: "bonuses",
        action: "getTransactions",
        session: getUserToken(),
        limit,
        page,
      })
      .then(({ data }) => {
        return data;
      });
  }

  getPromotion(stock, lang) {
    return api
      .post(null, {
        type: "other",
        action: "getStocksItem",
        lang,
        stock,
      })
      .then(({ data }) => {
        return data;
      });
  }

  getUserPromocode() {
    return api.post(null, {
      type: "users",
      action: "getPromocode",
      session: getUserToken(),
    }).then(({ data }) => {
      if (DataHelper.store.user) {
        DataHelper.store.user.promocode = data.promocode;
      }
      return data;
    })
  }

  getPromotions(lang) {
    return api
      .post(null, {
        type: "other",
        action: "getStocks",
        limit: 100,
        lang,
        page: 1,
      })
      .then(({ data }) => {
        return data;
      });
  }

  getRestaurantList() {
    return api
      .post(null, {
        type: "other",
        action: "getRestaurants",
        limit: 100,
        page: 1,
      })
      .then(({ data }) => {
        return data;
      });
  }

  getNewsById(news) {
    return api
      .post(null, {
        type: "other",
        action: "getNewsItem",
        news,
      })
      .then(({ data }) => {
        return data;
      });
  }

  getActualNews() {
    return api
      .post(null, {
        type: "other",
        action: "getNews",
        limit: 100,
        page: 1,
      })
      .then(({ data }) => {
        return data;
      });
  }

  getCities() {
    return api
      .post(null, {
        type: "delivery",
        action: "getCities",
        session: getUserToken(),
      })
      .then(({ data }) => {
        return data;
      });
  }

  createAddresses(addressData) {
    return api
      .post(null, {
        ...addressData,
        type: "delivery",
        action: "addAddress",
        session: getUserToken()
      })
  }

  getAddresses() {
    return api
      .post(null, {
        type: "delivery",
        action: "getAddresses",
        session: getUserToken(),
        anotherman: false
      }).then(({ data }) => data.addresses)
  }

  getWishes(lang) {
    return api
      .post(null, {
        type: "delivery",
        action: "getFavDishes",
        lang,
        session: getUserToken(),
      }).then(({ data }) => data.menuitems)
  }

  addWish(dish) {
    return api
      .post(null, {
        type: "delivery",
        action: "addFavDish",
        dish,
        session: getUserToken(),
      }).then(({ data }) => data.menuitems)
  }

  getAnothermanAddresses() {
    return api
      .post(null, {
        type: "delivery",
        action: "getAddresses",
        session: getUserToken(),
        anotherman: true
      }).then(({ data }) => data.addresses)
  }

  getBanners(lang) {
    return api
      .post(null, {
        type: "other",
        action: "getBanners",
        session: getUserToken(),
        limit: 100,
        lang,
        page: 1,
      })
      .then(({ data }) => {
        return data;
      });
  }

  getPaymentMethods() {
    return api.post(null, {
      type: "delivery",
      action: "getPaymentMethods",
      session: getUserToken()
    }).then(({ data }) => data.paymentmethods)
  }

  getCityMenu(city, lang) {
    return api
      .post(null, {
        type: "delivery",
        action: "getMenu",
        session: getUserToken(),
        city,
        lang
      })
      .then(({ data }) => {
        return data;
      });
  }

  getDishId(dish, city, lang) {
    return api
      .post(null, {
        type: "delivery",
        action: "getMenuItem",
        city,
        dish,
        lang,
        session: getUserToken(),
      })
      .then(({ data }) => data)
      .catch(error => console.log(error))
  }

  getAdditionalDishes(dishes, lang) {
    return api
      .post(null, {
        type: "delivery",
        action: "getAddDishes",
        dishes,
        lang,
        session: getUserToken()
      })
      .then(({ data }) => data.menuitems)
      .catch(error => console.log(error))
  }

  getDeliveryTimes(anotherman) {
    return api
      .post(null, {
        type: "delivery",
        action: "getDeliveryTimes",
        session: getUserToken(),
        anotherman: !!anotherman
      })
      .then(({ data }) => {
        return data.deliverytimes
      });
  }


  getCatering(payload) {
    return api
      .post(null, {
        ...payload,
        type: "other",
        action: "catering",
        session: getUserToken(),
      })
      .then(console.log);
  }

  orderTable(orderTableData) {
    const { phone, ...payload } = orderTableData;

    if (!getUserToken()) {
      return api.post(null, {
        ...payload,
        type: "other",
        action: "booking",
        phone: phone
      });
    }

    return api.post(null, {
      ...payload,
      type: "other",
      action: "booking",
      session: "c295307b-fe59-46dc-9926-1bbd800f61d1",
      phone: phone
    });
  }

  createOrder(deliveryData) {
    return api.post(null, {
      ...deliveryData,
      type: "delivery",
      action: "createOrder",
      session: getUserToken(),
    })
  }
  onlinePayment(order){
    return api.post(null, {
      type: "payments",
      action: "getPaymentLink",
      session: getUserToken(),
      order: order
    })
  }
  getPaymentStatus(order){
    return api.post(null, {
      type: "payments",
      action: "getPaymentStatus",
      session: getUserToken(),
      order: order
    })
  }
  getOrders(lang) {
    return api
      .post(null, {
        "limit": 100,
        "page": 1,
        type: "delivery",
        action: "getOrders",
        lang,
        session: getUserToken(),
      })
      .then(({ data }) => {
        return data.orders
      });
  }

  getOrder(order, lang) {
    return api
      .post(null, {
        type: "delivery",
        action: "getOrder",
        order,
        lang,
        session: getUserToken()
      })
      .then(({ data }) => {
        return data;
      });
  }

  getPolygonsCity(city) {
    return api
      .post(null, {
        type: "delivery",
        action: "getDeliveryRates",
        city,
        session: getUserToken()
    })
      .then(({ data }) => {
        return data;
      });

  }

  deleteAddress(address) {
    return api
      .post(null, {
        type: "delivery",
        action: "deleteAddress",
        address,
        session: getUserToken()
      })
      .then(({ data }) => {
        return data;
      });
  }


  // getWishes() {
  //   return api
  //     .post(null, {
  //       type: "delivery",
  //       action: "getAddDishes",
  //       session: getUserToken()
  //     })
  //     .then(({ data }) => {
  //       return data;
  //     });
  // }

  deleteWish(dish) {
    return api
      .post(null, {
        type: "delivery",
        action: "delFavDish",
        dish,
        session: getUserToken()
      })
      .then(({ data }) => {
        return data;
      });
  }

  leaveReview(reviewData) {
    return api
      .post(null, {
        ...reviewData,
        type: "other",
        action: "feedback",
      })
  }
}
