export function checkIfBrowserSafari() {
    let userAgentString = navigator.userAgent;
    let chromeAgent = 
                userAgentString.indexOf("Chrome") > -1;
    // Detect Safari
    let safariAgent = 
        userAgentString.indexOf("Safari") > -1;
    if ((chromeAgent) && (safariAgent)) 
    safariAgent = false;
    return safariAgent;
}