import IMask from "imask";

export function initMasks() {
    const { inputs } = DataHelper.store;

    if (inputs.guestPhone) {
        DataHelper.store.maskedInputs.push(
            IMask(
                inputs.guestPhone,
                {
                    mask: '8(700)000-00-00',
                    lazy: true,
                    placeholderChar: ''
                }),
        )
    }
    if (inputs.regPhone) {
        DataHelper.store.maskedInputs.push(
            IMask(
            inputs.regPhone,
            {
                mask: '8(700)000-00-00',
                lazy: true,
                placeholderChar: ''
            }),

        IMask(
            inputs.loginPhone,
            {
                mask: '8(700)000-00-00',
                lazy: true,
                placeholderChar: ''
            }),

        IMask(
            inputs.restorePassPhone,
            {
                mask: '8(700)000-00-00',
                lazy: true,
                placeholderChar: ''
            }),
        IMask(
            inputs.regName,
            {
                mask: /^[a-zA-Zа-яА-я]/,
                lazy: true,
                placeholderChar: ''
            }),
        )
    }

    const profileInput = document.querySelector('#profilePhone');

    if (profileInput) {
        DataHelper.store.maskedInputs.push(
            IMask(
            profileInput,
            {
                mask: '8(700)000-00-00',
                lazy: true,
                placeholderChar: ''
            })
        )

    }

    if (inputs.reviewFormNameInput) {
      DataHelper.store.maskedInputs.push(
        IMask(
          inputs.reviewFormPhoneInput,
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          inputs.reviewFormNameInput,
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          inputs.reviewFormMessageInput,
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(inputs.reservationPhone) {
      DataHelper.store.maskedInputs.push(
        IMask(
          inputs.reservationPhone,
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(document.querySelector("#checkout_guestname")
      && document.querySelector("#checkout_guestphone")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#checkout_guestname"),
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          document.querySelector("#checkout_guestphone"),
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(document.querySelector("#catering-form")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#catering-form .catering-name"),
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          document.querySelector("#catering-form .catering-phone"),
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(document.querySelector("#catering-banquet-form")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#catering-banquet-form .catering-name"),
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          document.querySelector("#catering-banquet-form .catering-phone"),
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(document.querySelector("#catering-buffet-form")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#catering-buffet-form .catering-name"),
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          document.querySelector("#catering-buffet-form .catering-phone"),
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }


    if(document.querySelector("#catering-kitchen-form")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#catering-kitchen-form .catering-name"),
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          document.querySelector("#catering-kitchen-form .catering-phone"),
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(document.querySelector("#discussPhoneInput")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#discussPhoneInput"),
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(document.querySelector("#callMeBackForm")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#callMeBackForm input[name='name']"),
          {
            mask: /^[a-zA-Zа-яА-я]/,
            lazy: true,
            placeholderChar: ''
        }),
        IMask(
          document.querySelector("#callMeBackForm input[name='phone']"),
          {
            mask: '8(700)000-00-00',
            lazy: true,
            placeholderChar: ''
        }),
      )
    }

    if(document.querySelector("#birth")) {
      DataHelper.store.maskedInputs.push(
        IMask(
          document.querySelector("#birth"),
          {
            mask: '00.00.0000',
            lazy: true,
            placeholderChar: 'ДД.ММ.ГГГГ'
          }),
      )
    }

}
