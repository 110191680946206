export function headerMenuScrollListener () {
    $(window).on("scroll", setMenuButton);
    $('#main-menu').on("click", setMenuButton);
}

function setMenuButton() {
    let cartButton = $('#products-summary');
    let header = $('header');

    let top = $(window).scrollTop();
    let bottomHeader = header.offset().top + header.outerHeight(true);
    let bottomCartButton = cartButton.offset().top + cartButton.outerHeight(true);
    let topCartButton = cartButton.offset().top;

    if (top > bottomCartButton) {
        cartButton.addClass("fixed");
    } else if(topCartButton <= bottomHeader){
        cartButton.removeClass("fixed");
    } 
}