import Toastify from 'toastify-js'

const toasts = []

export function toast(isWarn, message) {
    toasts.push({id: Date.now() / 1000, msg: message, isWarn })
    document.lastTimeToast = toasts.length > 1
        ? Math.floor(toasts[toasts.length - 1].id)
        : Math.floor(toasts[0].id)

    const bgColor = isWarn ?
        "#F27474" :
        "linear-gradient(to right, #00b09b, #96c93d)";

    Toastify({
        text: message,
        duration: 3000,
        style: {
            width: "350px",
            background: bgColor,
        }
    }).showToast();
}

