import $ from "jquery";
import {Api} from "../../src/api/Api";
import {getFormData} from "../functions/getFormData";
import {validateInputs} from "../helpers/InputValidator";
import { translate } from "../../views/main";

export function setReviewFormListener() {
  const reviewForm = $("#review-form");

  reviewForm.submit(function(e) {
    e.preventDefault();
    content.removeAllInputErrors();

    const formData = getFormData("#review-form");
    const isValidData = validateInputs("review");

    if (isValidData) {
      api.leaveReview(formData).then(() => {
        content.hideModalWindows(true);
        $(".popup").fadeOut();
        $("#success .modal-content")[0].innerHTML = `
              <h2>${translate('thank_you')}!</h2>
              <span>${translte('thanks_for_helping_better')}</span>
              <a href="/index.html" title="" class="button">${translate('go_back_to_main')}</a>
          `;
        $("#success").fadeIn();
      }).catch(
        (error) =>{
          content.hideModalWindows(true);
          console.log('Ошибка сервера');
          console.log(error);
        }
      )
      
    }
  })

}
