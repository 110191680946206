export function setCityChangeListener(cities) {
    [
        ...document.querySelectorAll("#cities a"),
        ...document.querySelectorAll("#cities-adaptive a")
    ].forEach((a) => {
        a.addEventListener("click", (e) => {
            e.preventDefault()
            const id = a.getAttribute("data-uuid");
            const city = cities.filter((city) => city.uuid === id)[0];

            localStorage.setItem("currentCity", JSON.stringify(city));
            location.reload()
        });
    });

}