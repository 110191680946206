import $ from "jquery";

export function getFormData(selector) {
  if (typeof selector !== "string") {
    return null;
  }
  let formData = {};
  $(selector)
    .serializeArray()
    .forEach((x) => {
      formData[x.name] = x.value
    });
  return formData;
}