export class GetDataHelper {
    constructor() {
        this.store = {
            user: {},
            bonuses: {},
            buttons: {},
            inputs: {},
            product: {},
            maskedInputs: []
        };
    }

    getElement(selector) {
        return document.querySelector(selector);
    }

}
